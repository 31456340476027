export const black = '#111'
export const darkGrey = '#222'
export const grey = '#555'
export const lightGrey = '#999'

export const main = '#1BABBC'

export const maxContainer = '1248px'

export const color = {
  offWhite: '#FFFFFF',
  primaryColor: '#1BABBC',
  secondaryColor: '#B0DFDC',
  fontColor: '#444444',
  transparent: '#FFFFFF00',
  error: '#cc0000',
}
