import styled, { css } from 'styled-components'
import {
  Radio as RadioAntd, Input as InputAntd, Col, Button as ButtonAntd, Row, InputNumber as InputNumberAntd, Select as SelectAntd,
} from 'antd'
import { maxContainer } from '../../style/_variable'
import { screen } from '../../constants/device-size'

export const Select = styled(SelectAntd)`
  width: calc(100% - 200px);
  margin-right: 25px;
`

export const MaxContainer = styled.div`
  max-width: ${maxContainer};
  width: 100%;
  margin: 0 auto;
  padding: 48px 70px;
  @media only screen and (max-width: ${screen.break}) {
    padding: 48px 32px;
  }
  @media only screen and (max-width: ${screen.smallBreak}) {
    padding: 48px 24px;
  }
  height: calc(100vh - 138px);
`

export const Main = styled(Row)`
  width: 100%;
  margin-bottom: 72px;
  @media only screen and (max-width: ${screen.break}) {
    margin-bottom: 0px;
    > div + div[class^='ant-col-'] > p,
    > div > div[class^='ant-col-'] > p {
      margin: 16px 0 !important;
    }
  }
`

export const Sub = styled(Main)`
  margin-bottom: 35px;
`

export const Form = styled.div`
  padding: 120px 0;
  padding-bottom: 0;
  @media only screen and (max-width: ${screen.break}) {
    padding: 80px 0 32px;
    > ${Main} {
      margin-bottom: 48px;
    }
    > ${Sub} {
      margin-bottom: 16px;
    }
  }
  

`

export const Last = styled(Main)`
  margin-bottom: 0;
`

export const SubMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Radio = styled(RadioAntd)`
  margin: 0 36px 0 0;
  font-family: 'Kanit';
  font-size: 16px;
`

export const Input = styled(InputAntd)`
  width: calc(100% - 200px);
  margin-right: 25px;
`

export const InputNumber = styled(InputNumberAntd)`
  width: calc(100% - 200px);
  margin-right: 25px;
`

export const CusCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
  align-items: center;
`

export const Button = styled(ButtonAntd)`
  height: 64px;
  width: 200px;
  background-color: #1babbc !important;
  border-color: #1babbc !important;
  font-family: 'Kanit';
  font-size: 22px;
  &[disabled] {
    border: 1px solid rgba(200,200,200,0.5) !important;
    background-color: rgba(200,200,200,0.5) !important;
  }
`

export const TitleRow = styled(Row)`
  margin-bottom: 5px;
`

export const DeviceWrapper = styled.div`
  height: 170px;
  border-radius: 8px;
  box-shadow: ${props => props.isSelected && '0 0 10px 0 rgba(176, 223, 220, 0.5)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => (props.isSelected ? 'rgba(27, 171, 188, 0.9)' : 'rgba(32, 32, 32, 0.5)')};
  cursor: pointer;
  padding: 10px;
`

export const DisabledWrapper = styled(DeviceWrapper)`
  border: 1px solid rgba(200,200,200,0.5);
  cursor: not-allowed;
  padding: 10px;
  background-color: rgba(200,200,200,0.5);
`

export const Icon = styled.i`
  font-size: 40px;
  color: ${props => (props.isSelected ? 'rgba(27, 171, 188, 0.9)' : '#686868')};
  margin: 16px 0;
`

export const GroupIcon = styled.div`
  position: relative;
  height: 72px;
  width: 75px;
  .fas {
    position: absolute;
    margin: 0;
    color: #686868;
  }
  .fa-desktop {
    font-size: 40px;
    top: 0;
  }
  .fa-keyboard {
    font-size: 28px;
    bottom: 0px;
    left: 16px;
  }
  .cpu {
    width: 22px;
    height: 40px;
    background-color: #686868;
    top: 0;
    right: 0;
    border-radius: 3px;
    &::before {
      content: '';
      width: 16px;
      height: 2px;
      position: absolute;
      top: 5px;
      right: 3px;
      background-color: white;
    }
  }
  .mouse {
      width: 9px;
      height: 14px;
      border-radius: 6px;
      background-color: #686868;
      bottom: 7px;
      right: 12px;
      &::before {
        content: '';
        width: 1px;
        height: 6px;
        background-color: white;
        position: absolute;
        right: 4px;
      }
    }
  ${props => props.isSelected && css`
    .fas {
      color: rgba(27, 171, 188, 0.9);
    }
    .mouse,
    .cpu {
      background-color: rgba(27, 171, 188, 0.9);
    }
  `}
`
