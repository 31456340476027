import React from 'react'
import { Col, Row } from 'antd'
import _ from 'lodash'
import numeral from 'numeral'
import getConst from '../../constants/laptop-spec'
import MenuContainer from '../../components/menu'
import {
  H3,
} from '../../components/common/text'
import { H4, H3Cus } from './styled'
import { Button, MaxContainer } from '../calculator/styled'
import history from '../../history'

let global = {
  ASH: 0, // Actual seat height
  AKH: 0, // Actual keyboard height
  AMH: 0, // Actaul monitor height
  ATH: 0, // Actaul table height
  FR: 0,
  FP: 0,
  SC: 0,
  KS: 0,
  MS: 0,
  RSHST: 0,
  RKHST: 0,
  RMHST: 0,
  RKHSD: 0,
  RMHSD: 0,
  NASH: 0,
  DM: 0,
  DK: 0,
  NAKH: 0,
  PS: 0,
  DT: 0,
  RTHST: 0,
  AB: 0,
  BS: 0,
  FX: 0,
}

export default class Result extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      result: {},
    }
  }

  seatHeightCal = ({ minChairHeight, maxChairHeight }) => {
    if (global.RSHST > maxChairHeight) {
      global.ASH = maxChairHeight
      global.SC += (global.RSHST - maxChairHeight)
    } else if (global.RSHST < minChairHeight) {
      global.ASH = minChairHeight
      global.FR += (minChairHeight - global.RSHST)
    } else {
      global.ASH = global.RSHST
    }
    global.RKHST += global.FR
  }

  nashMXSH = ({ maxChairHeight }) => {
    if (global.NASH > maxChairHeight) {
      global.SC += (global.NASH - maxChairHeight)
      global.FR += global.DK
      global.NASH = maxChairHeight
    } else {
      global.FR += global.DK
    }
  }

  NAKHMXKH = ({ maxKeyboardHeight }) => {
    if (global.NAKH > maxKeyboardHeight) {
      global.KS += (global.NAKH - maxKeyboardHeight)
      global.AKH = maxKeyboardHeight
    } else {
      global.AKH = global.NAKH
    }
  }

  keyboardHeightCal = ({ maxChairHeight }) => {
    if (global.RKHST > global.AKH) {
      global.KS += (global.RKHST - global.AKH)
    } else if (global.RKHST < global.AKH) {
      global.DK = global.AKH - global.RKHST
      global.NASH = global.ASH + global.DK
      this.nashMXSH({ maxChairHeight })
    }
  }

  dynamicKeyboardHeightCal = ({ minKeyboardHeight, maxKeyboardHeight, maxChairHeight }) => {
    if (global.RKHST > maxKeyboardHeight) {
      global.AKH = maxKeyboardHeight
      global.KS += (global.RKHST - maxKeyboardHeight)
    } else if (global.RKHST < minKeyboardHeight) {
      global.AKH = minKeyboardHeight
      global.DK = global.AKH - global.RKHST
      global.NASH = global.ASH + global.DK
      this.nashMXSH({ maxChairHeight })
    } else {
      global.AKH = global.RKHST
    }
  }

  maxSeatHeightCal = ({ maxChairHeight }) => {
    if (global.NASH > global.maxChairHeight) {
      global.SC += (global.NASH - maxChairHeight)
      global.FR += global.DM
      global.ASH = maxChairHeight
    } else {
      global.FR += global.DM
      global.ASH = global.NASH
    }
  }

  sitCalculate = ({
    gender, height, canAdjustMonitorHeight, canAdjustKeyboardHeight, minChairHeight, maxChairHeight, minMonitorHeight, maxMonitorHeight, minKeyboardHeight, maxKeyboardHeight,
  }) => {
    if (!gender) {
      global.RSHST = -21.927 + (0.400 * height)
      global.RKHST = -74.428 + (0.850 * height)
      global.RMHST = -38.698 + (0.953 * height)
    } else {
      global.RSHST = -24.964 + (0.413 * height)
      global.RKHST = -65.010 + (0.818 * height)
      global.RMHST = -38.305 + (0.958 * height)
    }
    if (canAdjustKeyboardHeight && canAdjustMonitorHeight) { // ------------------------ 1
      this.seatHeightCal({ // ------------------------------------------------------------------- 1.1
        minChairHeight, maxChairHeight,
      })
      this.dynamicKeyboardHeightCal({ // -------------------------------------------------------- 1.2
        minKeyboardHeight, maxKeyboardHeight, maxChairHeight,
      })
      global.RMHST += global.FR // -------------------------------------------------------------- 1.3
      if (global.RMHST > maxMonitorHeight) { // ------------------------------------------------- 1.4
        global.AMH = maxMonitorHeight
        global.MS += (global.RMHST - maxMonitorHeight)
        if (global.ASH < global.NASH) global.ASH = global.NASH
      } else if (global.RMHST < minMonitorHeight) {
        global.AMH = minMonitorHeight
        if (global.ASH < global.NASH) global.ASH = global.NASH
        global.DM = global.AMH - global.RMHST
        global.NASH = global.ASH + global.DM
        this.maxSeatHeightCal({ maxChairHeight })
        global.NAKH = global.AKH + global.DM
        this.NAKHMXKH({ maxKeyboardHeight })
      } else {
        global.AMH = global.RMHST
        if (global.ASH < global.NASH) global.ASH = global.NASH
      }
    } else if (canAdjustKeyboardHeight && !canAdjustMonitorHeight) { // ---------------- 2
      this.seatHeightCal({ // ------------------------------------------------------------------- 2.1
        minChairHeight, maxChairHeight,
      })
      this.dynamicKeyboardHeightCal({ // -------------------------------------------------------- 2.2
        minKeyboardHeight, maxKeyboardHeight, maxChairHeight,
      })
      global.RMHST += global.FR // -------------------------------------------------------------- 2.3
      if (global.RMHST > global.AMH) { // ------------------------------------------------------- 2.4
        global.MS += (global.RMHST - global.AMH)
        if (global.ASH < global.NASH) global.ASH = global.NASH
      } else if (global.RMHST < global.AMH) {
        if (global.ASH < global.NASH) global.ASH = global.NASH
        global.DM = global.AMH - global.RMHST
        global.NASH = global.ASH + global.DM
        this.maxSeatHeightCal({ maxChairHeight })
        global.NAKH = global.AKH + global.DM
        this.NAKHMXKH({ maxKeyboardHeight })
      } else if (global.ASH < global.NASH) global.ASH = global.NASH
    } else if (!canAdjustKeyboardHeight && canAdjustMonitorHeight) { // ---------------- 3
      this.seatHeightCal({ // ------------------------------------------------------------------ 3.1
        minChairHeight, maxChairHeight,
      })
      this.keyboardHeightCal({ maxChairHeight }) // -------------------------------------------- 3.2
      global.RMHST += global.FR // ------------------------------------------------------------- 3.3
      if (global.RMHST > maxMonitorHeight) { // ------------------------------------------------ 3.4
        global.AMH = maxMonitorHeight
        global.MS += (global.RMHST - maxMonitorHeight)
        if (global.ASH < global.NASH) global.ASH = global.NASH
      } else if (global.RMHST < minMonitorHeight) {
        global.AMH = minMonitorHeight
        global.KS += (global.AMH - global.RMHST)
        if (global.ASH < global.NASH) global.ASH = global.NASH
        global.DM = global.AMH - global.RMHST
        global.NASH = global.ASH + global.DM
        this.maxSeatHeightCal({ maxChairHeight })
      } else {
        global.AMH = global.RMHST
        if (global.ASH < global.NASH) global.ASH = global.NASH
      }
    } else { // ------------------------------------------------------------------------ 4
      this.seatHeightCal({ // ------------------------------------------------------------------ 4.1
        minChairHeight, maxChairHeight,
      })
      this.keyboardHeightCal({ maxChairHeight }) // -------------------------------------------- 4.2
      global.RMHST += global.FR // ------------------------------------------------------------- 4.3
      if (global.RMHST > global.AMH) { // ------------------------------------------------------ 4.4
        global.MS += (global.RMHST - global.AMH)
        if (global.ASH < global.NASH) global.ASH = global.NASH
      } else if (global.RMHST < global.AMH) {
        global.KS += (global.AMH - global.RMHST)
        if (global.ASH < global.NASH) global.ASH = global.NASH
        global.DM = global.AMH - global.RMHST
        global.NASH = global.ASH + global.DM
        this.maxSeatHeightCal({ maxChairHeight })
      } else if (global.ASH < global.NASH) global.ASH = global.NASH
    }
  }

  fixKeyboardHeightCal = () => {
    if (global.RKHSD > global.AKH) {
      global.KS += (global.RKHSD - global.AKH)
    } else if (global.RKHSD < global.AKH) {
      global.FP += (global.AKH - global.RKHSD)
      global.RMHSD += global.FP
    }
  }

  adjustKeyboardHeightCal = ({ minKeyboardHeight, maxKeyboardHeight }) => {
    if (global.RKHSD > maxKeyboardHeight) {
      global.AKH = maxKeyboardHeight
      global.KS += (global.RKHSD - maxKeyboardHeight)
    } else if (global.RKHSD < minKeyboardHeight) {
      global.AKH = minKeyboardHeight
      global.FP += (minKeyboardHeight - global.RKHSD)
      global.RMHSD += global.FP
    } else {
      global.AKH = global.RKHSD
    }
  }

  standCalculate = ({
    gender, height, minKeyboardHeight, maxKeyboardHeight, maxMonitorHeight, minMonitorHeight,
  }) => {
    if (gender === 0) {
      global.RKHSD = -24.796 + (0.762 * height)
      global.RMHSD = -7.675 + (0.976 * height)
    } else {
      global.RKHSD = -16.991 + (0.724 * height)
      global.RMHSD = -5.909 + (0.964 * height)
    }

    if (minKeyboardHeight) { // keyboard adjust
      this.adjustKeyboardHeightCal({ minKeyboardHeight, maxKeyboardHeight })
      if (minMonitorHeight) { // monitor adjust ---------------------- 1
        if (global.RMHSD > maxMonitorHeight) {
          global.AMH = maxMonitorHeight
          global.MS += (global.RMHSD - maxMonitorHeight)
        } else if (global.RMHSD < minMonitorHeight) {
          global.AMH = minMonitorHeight
          global.DM = (minMonitorHeight - global.RMHSD)
          global.NAKH = (global.AKH + global.DM)
          if (global.NAKH > maxKeyboardHeight) {
            global.AKH = maxKeyboardHeight
            global.KS += (global.NAKH - maxKeyboardHeight)
          } else {
            global.AKH = global.NAKH
          }
          global.FP += global.DM
        } else {
          global.AMH = global.RMHSD
        }
      } else if (global.RMHSD > global.AMH) { // monitor fix ---------------------- 2
        global.MS += (global.RMHSD - global.AMH)
      } else if (global.RMHSD < global.AMH) {
        global.DM = global.AMH - global.RMHSD
        const NAKH = global.AKH + global.DM
        if (NAKH > maxKeyboardHeight) {
          global.AKH = maxKeyboardHeight
          global.KS += (NAKH - maxKeyboardHeight)
        } else {
          global.AKH = NAKH
        }
        global.FP += global.DM
      }
    } else { // keyboard fix
      this.fixKeyboardHeightCal()
      if (minMonitorHeight) { // monitor adjust  ----------------------- 3
        if (global.RMHSD > maxMonitorHeight) {
          global.AMH = maxMonitorHeight
          global.MS += (global.RMHSD - maxMonitorHeight)
        } else if (global.RMHSD < minMonitorHeight) {
          global.AMH = minMonitorHeight
          global.KS += (minMonitorHeight - global.RMHSD)
          global.FP += (minMonitorHeight - global.RMHSD)
        } else {
          global.AMH = global.RMHSD
        }
      } else if (global.RMHSD > global.AMH) { // monitor fix  ----------- 4
        global.MS += (global.RMHSD - global.AMH)
      } else if (global.RMHSD < global.AMH) {
        global.KS += (global.AMH - global.RMHSD)
        global.FP += (global.AMH - global.RMHSD)
      }
    }
  }

  // ------------------------------------------------------------------------ //

  getCompareSeatHeight = (maxChairHeight, minChairHeight) => {
    if (global.RSHST > maxChairHeight) {
      global.ASH = maxChairHeight
      global.SC += global.RSHST - maxChairHeight
    } else if (global.RSHST < minChairHeight) {
      global.ASH = minChairHeight
      global.FR += minChairHeight - global.RSHST
    } else {
      global.ASH = global.RSHST
    }
    global.RTHST += global.FR
  }

  reAdjustSeatHeight = (maxChairHeight) => {
    global.DT = global.ATH - global.RTHST
    global.NASH = global.ASH + global.DT
    if (global.NASH > maxChairHeight) {
      global.SC += global.NASH - maxChairHeight
      global.FR += global.DT
      global.ASH = maxChairHeight
    } else {
      global.FR += global.DT
      global.ASH = global.NASH
    }
  }

  sitLaptopCalculate = ({
    gender, height, NBC, canAdjustTableHeight, minimumTableHeight, maximumTableHeight, minChairHeight, maxChairHeight,
  }) => {
    const fixedValue = _.filter(getConst(gender, NBC), o => o.BH === height)[0]
    global.RSHST = fixedValue.RSHST
    global.RTHST = fixedValue.RTHST
    global.AB = fixedValue.AB
    global.BS = fixedValue.BS
    global.FX = fixedValue.FX
    if (canAdjustTableHeight) {
      this.getCompareSeatHeight(maxChairHeight, minChairHeight)
      if (global.RTHST > global.ATH) {
        global.PS += global.RTHST - global.ATH
      } else if (global.RTHST < global.ATH) {
        this.reAdjustSeatHeight(maxChairHeight)
      }
    } else {
      this.getCompareSeatHeight(maxChairHeight, minChairHeight)
      if (global.RTHST > maximumTableHeight) {
        global.ATH = maximumTableHeight
        global.PS += global.RTHST - maximumTableHeight
      } else if (global.RTHST < minimumTableHeight) {
        global.ATH = minimumTableHeight
        this.reAdjustSeatHeight(maxChairHeight)
      } else {
        global.ATH = global.RTHST
      }
    }
  }

  // ------------------------------------------------------------------------ //

  calculate = (params) => {
    if (params.deviceType) {
      this.sitLaptopCalculate({ ...params })
    } else if (!params.posture) this.sitCalculate({ ...params })
    else this.standCalculate({ ...params })
  }

  componentDidMount() {
    if (_.isUndefined(this.props.location.state)) history.push('/calculator')
  }

  render() {
    const {
      gender, height, posture,
      canAdjustMonitorHeight, actualMonitorHeight, minMonitorHeight, maxMonitorHeight,
      canAdjustKeyboardHeight, actualKeyboardHeight, minKeyboardHeight, maxKeyboardHeight,
      minChairHeight, maxChairHeight, deviceType, canAdjustTableHeight, actualTableHeight, minimumTableHeight, maximumTableHeight,
    } = this.props.location.state
    global = {
      ...global,
      ASH: this.props.location.state.actualChairHeight,
      AKH: this.props.location.state.actualKeyboardHeight,
      AMH: this.props.location.state.actualMonitorHeight,
      ATH: this.props.location.state.actualTableHeight,
      FR: 0,
      SC: 0,
      KS: 0,
      MS: 0,
      FP: 0,
      PS: 0,
      DT: 0,
      RTHST: 0,
      AB: 0,
      BS: 0,
      FX: 0,
      NBC: this.props.location.state.NBC,
    }
    this.calculate(this.props.location.state)
    return (
      <div>
        <MenuContainer solid={true} />
        <MaxContainer>
          <Row gutter={32} justify='center' type='flex'>
            <Col xs={24} lg={24}>
              <H3 bold margin='0 0 80px 0'>ผลคำนวนค่าปรับตั้งสถานีงานคอมพิวเตอร์</H3>
              <H3Cus margin='0 0 32px 0'>ค่าปรับตั้งที่แนะนำ</H3Cus>
              <Row gutter={32}>
                <Col xs={14} lg={4}>
                  <H4>{deviceType ? 'ความสูงโต๊ะ' : 'ระดับจอภาพ'}</H4>
                </Col>
                <Col xs={10} lg={4}>
                  <H4 bold>{numeral(deviceType ? global.ATH : global.AMH).format('0,0')} ซม</H4>
                </Col>
                <Col xs={14} lg={4}>
                  <H4>{deviceType ? 'องศาจอ' : 'ระดับแป้นพิมพ์'}</H4>
                </Col>
                <Col xs={10} lg={4}>
                  <H4 bold>{numeral(deviceType ? global.BS : global.AKH).format('0,0')} {deviceType ? 'องศา' : 'ซม'}</H4>
                </Col>
                {
                  !posture
                    && [
                      <Col key='key' xs={14} lg={4}>
                        <H4>ระดับเบาะนั่ง</H4>
                      </Col>,
                      <Col key='value' xs={10} lg={4}>
                        <H4 bold>{numeral(global.ASH).format('0,0')} ซม</H4>
                      </Col>,
                    ]
                }
              </Row>
              <Row gutter={32} style={{ marginBottom: 72 }}>
                <Col xs={14} lg={4}>
                  <H4>{deviceType ? 'ความสูงที่เสริม' : 'ที่เสริมจอภาพ'}</H4>
                </Col>
                <Col xs={10} lg={4}>
                  <H4 bold>{numeral(deviceType ? global.PS : global.MS).format('0,0')} ซม</H4>
                </Col>
                <Col xs={14} lg={4}>
                  <H4>{deviceType ? 'มุมของที่เสริม' : 'ที่เสริมแป้นพิมพ์'}</H4>
                </Col>
                <Col xs={10} lg={4}>
                  <H4 bold>{numeral(deviceType ? global.AB : global.KS).format('0,0')} {deviceType ? 'องศา' : 'ซม'}</H4>
                </Col>
                {
                  !posture
                    && [
                      <Col key='key' xs={14} lg={4}>
                        <H4>ที่เสริมเบาะนั่ง</H4>
                      </Col>,
                      <Col key='value' xs={10} lg={4}>
                        <H4 bold>{numeral(global.SC).format('0,0')} ซม</H4>
                      </Col>,
                    ]
                }
                <Col xs={14} lg={4}>
                  <H4>{ !posture ? 'ที่พักเท้า' : 'ระดับยกพื้น' }</H4>
                </Col>
                <Col xs={10} lg={4}>
                  <H4 bold>{ !posture ? `${numeral(global.FR).format('0,0')} ซม` : `${numeral(global.FP).format('0,0')} ซม`}</H4>
                </Col>
                {
                  deviceType === 1
                    && [<Col key='key' xs={14} lg={4}>
                          <H4>ระยะห่างระหว่างบุคคลกับโน้ตบุ๊ค</H4>
                        </Col>,
                        <Col key='value' xs={10} lg={4}>
                          <H4 bold>{numeral(global.FX).format('0,0')} ซม</H4>
                        </Col>]
                }
              </Row>
              <H3 margin='0 0 32px 0'>ข้อมูลที่ได้รับ</H3>
              <Row gutter={32} style={{ marginBottom: 100 }} type='flex'>
                <Col xs={14} lg={5}>
                  <H4>เพศ</H4>
                </Col>
                <Col xs={10} lg={6}>
                  <H4 bold>{!gender ? 'ชาย' : 'หญิง'}</H4>
                </Col>
                {
                  !deviceType
                    ? [<Col key='key' xs={14} lg={6}>
                        <H4>ระดับจอภาพ {!canAdjustMonitorHeight ? 'ปรับไม่ได้' : 'ปรับได้'}</H4>
                      </Col>,
                      <Col key='value' xs={10} lg={6}>
                        <H4 bold>{!canAdjustMonitorHeight ? `${actualMonitorHeight} ซม` : `ต่ำสุด ${minMonitorHeight} ซม. / สูงสุด ${maxMonitorHeight} ซม.`}</H4>
                      </Col>]
                    : [<Col key='key' xs={14} lg={6}>
                        <H4>ระดับโต๊ะ {!canAdjustTableHeight ? 'ปรับไม่ได้' : 'ปรับได้'}</H4>
                      </Col>,
                      <Col key='value' xs={10} lg={6}>
                        <H4 bold>{!canAdjustTableHeight ? `${actualTableHeight} ซม` : `ต่ำสุด ${minimumTableHeight} ซม. / สูงสุด ${maximumTableHeight} ซม.`}</H4>
                      </Col>]
                }
                <Col xs={14} lg={5}>
                  <H4>ส่วนสูง</H4>
                </Col>
                <Col xs={10} lg={6}>
                  <H4 bold>{height} ซม</H4>
                </Col>
                {
                  !deviceType
                    && [<Col key='key' xs={14} lg={6}>
                          <H4>ระดับแป้นพิมพ์  {!canAdjustKeyboardHeight ? 'ปรับไม่ได้' : 'ปรับได้'}</H4>
                        </Col>,
                        <Col key='value' xs={10} lg={6}>
                          <H4 bold>{!canAdjustKeyboardHeight ? `${actualKeyboardHeight} ซม` : `ต่ำสุด ${minKeyboardHeight} ซม. / สูงสุด ${maxKeyboardHeight} ซม.`}</H4>
                        </Col>]
                }
                <Col order={deviceType && 9} xs={14} lg={5}>
                  <H4>ประเภท</H4>
                </Col>
                <Col order={deviceType && 10} xs={10} lg={6}>
                  <H4 bold>{deviceType ? 'คอมพิวเตอร์ตั้งโต๊ะ' : 'คอมพิวเตอร์พกพา'}</H4>
                </Col>
                <Col order={deviceType && 7} xs={14} lg={6}>
                  <H4>ระดับเบาะนั่ง</H4>
                </Col>
                {
                  posture === 0
                    ? <Col order={deviceType && 8} xs={10} lg={7}>
                        <H4 bold>ต่ำสุด {minChairHeight} ซม. / สูงสุด {maxChairHeight} ซม.</H4>
                      </Col>
                    : <Col order={deviceType && 8} xs={10} lg={7}>
                        <H4 bold>ยืนปฏิบัติงาน</H4>
                      </Col>
                }
              </Row>
            </Col>
          </Row>
          <Row type='flex' justify='center' style={{ marginBottom: 170 }}>
            <a href='/calculator'>
              <Button type="primary">คำนวณใหม่</Button>
            </a>
          </Row>
        </MaxContainer>
      </div>
    )
  }
}
