import React from 'react'
import { Row, Col } from 'antd'
import numeral from 'numeral'
import { P } from '../common/text'
import { color } from '../../style/_variable'

export default ({ activeUser }) => (
  <Row>
    <Col span={24} style={{ backgroundColor: color.secondaryColor }}>
      <P padding='8px 0' margin='0' color='#585858' align='center'>
        copyright by Compwork v.1.0.8 | active users: {numeral(activeUser).format('0,0')}
      </P>
    </Col>
  </Row>
)
