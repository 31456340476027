import React from 'react'
import { Row, Col } from 'antd'
import YouTube from 'react-youtube'
import {
  Wrapper, Image,
} from './styled'
import { H2, H3, P } from '../../components/common/text'

export default ({ data }) => (
  <Wrapper>
    <Row justify='center' type='flex' style={{ marginBottom: 56 }}>
        <H2>{data.title}</H2>
    </Row>
    <Row justify='center' type='flex' style={{ marginBottom: 32 }}>
        <H3>{data.subTitle}</H3>
    </Row>
    <Row style={{ marginBottom: 32 }}>
      {
        data.detail.map((v, i) => (
          <Col key={i} span={24}>
            <P>{i + 1}. {v}</P>
          </Col>
        ))
      }
    </Row>
    <Row gutter={32} style={{ marginBottom: 50 }}>
      {
        data.images.map((v, i) => (
          <Col key={i} lg={12} md={24}>
            <Image src={v.link}/>
            <P align='center' margin='16 0 0 0'>{v.label}</P>
          </Col>
        ))
      }
    </Row>
    <H3 margin='0 0 47px 0'>{data.videoLabel}</H3>
    <Row style={{ marginBottom: 120 }}>
      <Col span={24}>
        <YouTube
          opts={{
            width: '100%',
            height: '481',
            playerVars: {
              rel: 0,
              loop: 1,
              playlist: data.video,
            },
          }}
          videoId={data.video}
        />
      </Col>
    </Row>
  </Wrapper>
)
