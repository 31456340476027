import styled from 'styled-components'
import { screen } from '../../constants/device-size'
import { maxContainer } from '../../style/_variable'

export const ContainerWrapper = styled.div``

export const MaxWidthContainer = styled.div`
    max-width: ${maxContainer};
    width: 100%;
    margin: 0 auto;
    padding: 48px 70px;
    @media only screen and (max-width: ${screen.break}) {
        padding: 48px 32px;
    }
    @media only screen and (max-width: ${screen.smallBreak}) {
        padding: 48px 24px;
    }
`
