/* eslint-disable */
import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/common/breadcrumb'
import Detail from './detail'
import MenuContainer from '../../components/menu'
import { Card, MobileCard, MobileCardWrapper } from './styled'
import { P } from '../../components/common/text'
import { color } from '../../style/_variable'

const detail = [
  {
    title: 'ท่าบริหารคอและไหล่',
    subTitle: 'ท่าที่ 1 –  ท่าบริหารกล้ามเนื้อคอมัดลึก',
    detail: [
      'นั่งหลังตรง (ภาพที่ 1-1)',
      'เก็บคาง โดยพยายามเลื่อนคางไปด้านหลังในแนวระนาบ ไม่ใช่การก้มหน้า (ภาพที่ 1-2) เกร็งกล้ามเนื้อคอบริเวณใต้คางนี้ค้างไว้ 5-10 วินาที พัก และทำซ้ำ 5-10 ครั้ง หรือก่อนจะรู้สึกล้า',
      'ให้พยายามเก็บคางให้ได้ตลอดวัน โดยเกร็งกล้ามเนื้อคอมัดลึกนี้เบา ๆ หรือออกแรงเพียง 10% ของแรงที่เกร็งเต็มที่ ถ้าทำได้จะช่วยป้องกันไม่ให้คางยื่นและหลังค่อมเวลานั่งปฏิบัติ. งานกับเครื่องคอมพิวเตอร์ได้',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 1-1',
        link: require('../../images/content/1/11.jpg'),
      }, {
        label: 'ภาพที่ 1-2',
        link: require('../../images/content/1/12.jpg'),
      },
    ],
    videoLabel: 'วีดีโอท่าบริหารกล้ามเนื้อคอมัดลึก',
    video: '-Roq4bYRngg',
  }, {
    title: 'ท่าบริหารคอและไหล่',
    subTitle: 'ท่าที่ 2 – ท่ายืดกล้ามเนื้อคอและบ่า',
    detail: [
      'นั่งหลังตรง',
      'มือซ้ายเหยียดศอก จับยึดใต้ขอบเก้าอี้ที่นั่งตรึงไว้ (ภาพที่ 2-1)',
      'หันศีรษะไปทางซ้ายเท่าที่ทำได้',
      'ใช้มือขวาจับที่เหนือศีรษะทางด้านซ้าย แล้วดึงศีรษะมาทางด้านขวาช้า ๆ จนรู้สึกตึง ค้างไว้ 30วินาที (ภาพที่ 2-2)',
      'ทำซ้ำอีกข้าง โดยมือขวาเหยียดศอก จับยึดใต้ขอบเก้าอี้นั่งตรึงไว้',
      'หันศีรษะไปทางขวาเท่าที่ทำได้',
      'ใช้มือซ้ายจับที่เหนือศีรษะทางด้านขวา แล้วดึงศีรษะมาทางด้านซ้ายช้า ๆ จนรู้สึกตึง ค้างไว้ 30วินาที',
      'ทำทั้ง 2 ข้างสลับไปมาประมาณ 5-10 ครั้ง ทำได้ทุกชั่วโมงหากมีอาการตึงที่บ่ามาก',
      'ทำทั้ง 2 ข้างสลับไปมาประมาณ 5-10 ครั้ง ทำได้ทุกชั่วโมงหากมีอาการตึงที่บ่ามาก',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 2-1',
        link: require('../../images/content/1/21.jpg'),
      }, {
        label: 'ภาพที่ 2-2',
        link: require('../../images/content/1/22.jpg'),
      },
    ],
    videoLabel: 'วีดีโอท่ายืดกล้ามเนื้อคอและบ่า',
    video: 'QCvDG17h87U',
  }, {
    title: 'ท่าบริหารคอและไหล่',
    subTitle: 'ท่าที่ 3 – ท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนกลาง',
    detail: [
      'นั่งหลังตรง เก็บคาง',
      'ตั้งศอก โดยงอศอกประมาณ 90 องศา กางศอกออกด้านข้างลำตัวในท่าคว่ำมือลง (ภาพที่ 3-1)',
      'ดึงศอกไปข้างหลัง (ภาพที่ 3-2) โดยเกร็งกล้ามเนื้อระหว่างสะบักหนีบสะบักเข้าหากัน ค้างไว้ประมาณ 5-10 วินาที แล้วผ่อน เกร็งซ้ำประมาณ 10-20 ครั้ง หรือก่อนมีอาการล้า',
      'ท่านี้สามารถทำได้ระหว่างการนั่งทำงาน โดยทำได้ทุก ๆ ชั่วโมง',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 3-1',
        link: require('../../images/content/1/31.jpg'),
      }, {
        label: 'ภาพที่ 3-2',
        link: require('../../images/content/1/32.jpg'),
      },
    ],
    videoLabel: 'วีดีโอท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนกลาง',
    video: 'cUJbzpeLBCM',
  }, {
    title: 'ท่าบริหารคอและไหล่',
    subTitle: 'ท่าที่ 4 – ท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนล่าง',
    detail: [
      'ยืนหลังตรง',
      'มือทั้งสองวางบริเวณตะโพกด้านหลังทั้งสองข้าง หันฝ่ามือออกจากตัว (ภาพที่ 4-1)',
      'เลื่อนมือทั้งสองเข้ามาไขว้กัน โดยเกร็งกล้ามเนื้อด้านในสะบักส่วนล่าง (ภาพที่ 4-2)',
      'เกร็งกล้ามเนื้อค้างไว้ประมาณ 10-20 วินาที พัก และทำซ้ำต่อเนื่อง 5-10 ครั้ง หรือก่อนจะรู้สึกล้า',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 4-1',
        link: require('../../images/content/1/41.jpg'),
      }, {
        label: 'ภาพที่ 4-2',
        link: require('../../images/content/1/42.jpg'),
      },
    ],
    videoLabel: 'วีดีโอท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนล่าง',
    video: 'Lvi2gas_KdA',
  }, {
    title: 'ท่าบริหารคอและไหล่',
    subTitle: 'ท่าที่ 5 – ท่ายืดกล้ามเนื้อบริเวณทรวงอก',
    detail: [
      'นั่งหลังตรง',
      'ยกมือทั้งสองข้างประสานกันไว้ที่ท้ายทอย (ภาพที่ 5-1 และ 5-2)',
      'เอนศีรษะและลำตัวส่วนบนไปแตะที่พนักพิงด้านหลังจนรู้สึกตึงบริเวณกล้ามเนื้อทรวงอกทั้งสองข้าง (ภาพที่ 5-3)',
      'ยืดค้างไว้ 20-30 วินาที ทำซ้ำวันละ 6-10 ครั้ง',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 5-1',
        link: require('../../images/content/1/51.jpg'),
      }, {
        label: 'ภาพที่ 5-2',
        link: require('../../images/content/1/52.jpg'),
      }, {
        label: 'ภาพที่ 5-3',
        link: require('../../images/content/1/53.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่ายืดกล้ามเนื้อบริเวณทรวงอก',
    video: 'KklMvTtHMRI',
  }, {
    title: 'ท่าบริหารข้อมือ',
    subTitle: 'ท่าที่ 1 – ท่ายืดกล้ามเนื้อแขนและมือ',
    detail: [
      'นั่งหลังตรง',
      'เหยียดแขนซ้ายไปข้างหน้า หงายฝ่ามือขึ้น (ภาพที่ 1-1)',
      'ใช้มือขวาจับปลายนิ้วทั้งห้ากดลงจนรู้สึกตึง ค้างไว้ประมาณ 20 วินาที (ภาพที่ 1-2)',
      'ให้ทำซ้ำอีกข้าง',
      'ยืดค้างไว้ 20-30 วินาที ทำซ้ำวันละ 6-10 ครั้ง',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 1-1',
        link: require('../../images/content/2/11.jpg'),
      }, {
        label: 'ภาพที่ 1-2',
        link: require('../../images/content/2/12.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่ายืดกล้ามเนื้อแขนและมือ',
    video: 'hYbT3Yrq9nA',
  }, {
    title: 'ท่าบริหารหลังส่วนล่าง',
    subTitle: 'ท่าที่ 1 – ท่าบริหารหลังส่วนล่าง',
    detail: [
      'ยืนหลังตรง',
      'เก็บคาง มือทั้งสองข้างจับบริเวณตะโพกด้านหลังทั้งสองข้าง (ภาพที่ 1-1)',
      'ใช้มือทั้งสองดันตะโพกไปด้านหน้าจนตัวเอนไปทางด้านหลัง (ภาพที่ 1-2)',
      'พักโดยผ่อนแรงที่มือกลับสู่ท่าเริ่มต้น ทำซ้ำต่อเนื่อง 10-15 ครั้งต่อรอบ ทำวันละ 6-10 รอบ หรือทุก ๆ ชั่วโมงของการทำงาน',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 1-1',
        link: require('../../images/content/3/12.jpg'),
      }, {
        label: 'ภาพที่ 1-2',
        link: require('../../images/content/3/22.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่าบริหารหลังส่วนล่าง',
    video: 'Ll2ewk5HBFs',
  }, {
    title: 'ท่าบริหารหลังส่วนล่าง',
    subTitle: 'ท่าที่ 2 – ท่าบริหารกล้ามเนื้อรอบเอว',
    detail: [
      'เลื่อนตัวมานั่งบริเวณกึ่งกลางของฐานเก้าอี้',
      'นั่งหลังตรง',
      'มือทั้งสองข้างเอื้อมไปแตะบริเวณกึ่งกลางหลังส่วนล่างใกล้กระเบนเหน็บ (ภาพที่ 2-1)',
      'ใช้นิ้วหัวแม่มือทั้งสองข้างดันหลังไปด้านหน้าให้เชิงกรานหมุนไปด้านหน้าทำให้หลังส่วนล่างแอ่นไปข้างหน้ามากขึ้น (ภาพที่ 2-2)',
      'เกร็งค้างไว้ประมาณ 10-20 วินาที พัก และทำซ้ำต่อเนื่อง 5-10 ครั้ง หรือก่อนจะรู้สึกล้า',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 2-2',
        link: require('../../images/content/3/21.jpg'),
      }, {
        label: 'ภาพที่ 2-2',
        link: require('../../images/content/3/22.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่าบริหารกล้ามเนื้อรอบเอว',
    video: '_t3HEtsz6gM',
  }, {
    title: 'ท่าบริหารหลังส่วนล่าง',
    subTitle: 'ท่าที่ 3 – ท่าบริหารกล้ามเนื้อตะโพกด้านหลัง',
    detail: [
      'อยู่ในท่ายืน มือทั้งสองข้างวางบนโต๊ะให้ปลายนิ้วชี้ไปด้านหน้า เท้าห่างจากโต๊ะเล็กน้อย หลังตรง งอที่ข้อตะโพก (ภาพที่ 3-1)',
      'ให้ยกขาซ้ายขึ้นทางด้านหลัง โดยเกร็งกล้ามเนื้อบริเวณตะโพกด้านหลัง ในขณะเดียวกัน ให้แขม่วท้องไว้ไม่ให้หลังแอ่น จนแนวของหลังและขาอยู่ในแนวเดียวกัน (ภาพที่ 3-2)',
      'เกร็งกล้ามเนื้อค้างไว้ประมาณ 10-15 วินาที พักและทำซ้ำอีกข้าง ทำสลับไปมาจำนวน 10-15ครั้งต่อข้าง หรือก่อนจะรู้สึกล้า',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 3-1',
        link: require('../../images/content/3/31.jpg'),
      }, {
        label: 'ภาพที่ 3-2',
        link: require('../../images/content/3/32.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่าบริหารกล้ามเนื้อตะโพกด้านหลัง',
    video: 'loZLKMXkZv8',
  }, {
    title: 'ท่าบริหารขา หัวเข่า และข้อเท้า',
    subTitle: 'ท่าที่ 1 – ท่าบริหารกล้ามเนื้อเข่าและข้อเท้า',
    detail: [
      'นั่งพิงพนักเก้าอี้ ให้ต้นขาที่อยู่เหนือเข่าอยู่บนฐานที่นั่งของเก้าอี้ เท้าทั้งสองข้างวางที่พื้น (ภาพที่ 1-1)',
      'ยกเท้าทั้งสองข้างขึ้นจากพื้น โดยเหยียดเข่าขึ้นตรง หลังจากนั้น กระดกข้อเท้าขึ้นและลงสลับกันต่อเนื่อง 20-30 ครั้ง จะรู้สึกน่องตึงขณะกระดกข้อเท้าขึ้น และรู้สึกหน้าแข้งตึงเมื่อกระดกข้อเท้าลง (ภาพที่ 1-2)',
      'ให้หายใจตามปกติ ไม่ควรกลั้นหายใจระหว่างการบริหาร',
    ],
    images: [
      {
        label: 'ภาพที่ 1-2',
        link: require('../../images/content/4/11.jpg'),
      }, {
        label: 'ภาพที่ 1-2',
        link: require('../../images/content/4/12.jpg'),
      },
    ],
    videoLabel: 'วิดีโอท่าบริหารกล้ามเนื้อเข่าและข้อเท้า',
    video: 'neCrNiB7OlU',
  },
]

const cardWidgetData = (param) => {
  const id = parseInt(param, 10)
  if (id < 6) {
    return (
      [
        {
          title: 'ท่าบริหารกล้ามเนื้อคอมัดลึก',
          image: require('../../images/content/1/11.jpg'),
          link: '1'
        }, {
          title: 'ท่ายืดกล้ามเนื้อคอและบ่า',
          image: require('../../images/content/1/21.jpg'),
          link: '2'
        }, {
          title: 'ท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนกลาง',
          image: require('../../images/content/1/31.jpg'),
          link: '3'
        }, {
          title: 'ท่าบริหารกล้ามเนื้อระหว่างสะบักส่วนล่าง',
          image: require('../../images/content/1/41.jpg'),
          link: '4'
        }, {
          title: 'ท่ายืดกล้ามเนื้อบริเวณทรวงอก',
          image: require('../../images/content/1/51.jpg'),
          link: '5'
        }, 
      ]
    )
  } else if (id === 6 ) {
    return (
      [
        {
          title: 'ท่ายืดกล้ามเนื้อแขนและมือ',
          image: require('../../images/content/2/11.jpg'),
          link: '6'
        }
      ]
    )
  } else if (id < 10 ) {
    return (
      [
        {
          title: 'ท่าบริหารหลังส่วนล่าง',
          image: require('../../images/content/3/11.jpg'),
          link: '7'
        }, {
          title: 'ท่าบริหารกล้ามเนื้อรอบเอว',
          image: require('../../images/content/3/21.jpg'),
          link: '8'
        }, {
          title: 'ท่าบริหารกล้ามเนื้อตะโพกด้านหลัง',
          image: require('../../images/content/3/31.jpg'),
          link: '9'
        }, 
      ]
    )
  } else {
    return (
      [
        {
          title: 'ท่าบริหารกล้ามเนื้อเข่าและข้อเท้า',
          image: require('../../images/content/4/11.jpg'),
          link: '10'
        }
      ]
    )
  }
}

class ContentContainer extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      isDesktop: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 767
    }
  }

  jsUpdateSize = () => {
    if (!this.state.isDesktop && ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 767)) this.setState({ isDesktop: true })
    else if (this.state.isDesktop && ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768)) this.setState({ isDesktop: false })
  }

  render() {
    window.onresize = this.jsUpdateSize
    const list = [
      {
        label: 'หน้าแรก',
        link: '/',
      }, {
        label: 'ท่าบริหาร',
        link: '/pose',
      }, {
        label: detail[this.props.match.params.id - 1].title,
      },
    ]
    return (
      <div>
        <MenuContainer solid={true} />
        <Row justify='center' type='flex' style={{ padding: '55px 0' }}>
          <Col xs={21} lg={18} xl={16}>
            <Breadcrumb list={list} />
            {
              this.state.isDesktop
                ? <DesktopCardWidget id={this.props.match.params.id} />
                : <MobileCardWidget id={this.props.match.params.id} />
            }
            <Detail data={detail[this.props.match.params.id - 1]}/>
          </Col>
        </Row>
      </div>
    )
  }
}

const DesktopCardWidget = (param) => (
  <Row gutter={16} style={{ margin: '15px -120px 0 -120px' }} type='flex' justify='center'>
  {
    cardWidgetData(param.id).map(v => (
      <Col span={4} key={v.link}>
        <Link to={v.link}>
          <Card background={v.image} isActive={v.link === param.id}>
            <P bold color={color.offWhite}>{v.title}</P>
          </Card>
        </Link>
      </Col>
    ))
  }
  </Row>
)

const MobileCardWidget = (param) => (
  <MobileCardWrapper>
  {
    cardWidgetData(param.id).map(v => (
      <div>
        <MobileCard background={v.image} key={v.link} to={v.link} isActive={v.link === param.id}>
          <P bold color={color.offWhite}>{v.title}</P>
        </MobileCard>
      </div>
    ))
  }
  </MobileCardWrapper>
)

export default ContentContainer
