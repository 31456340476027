import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Row } from 'antd'
import Bg from '../../images/bg1.png'
import { color, maxContainer } from '../../style/_variable'
import { screen } from '../../constants/device-size'

export const MaxContainer = styled.div`
  max-width: ${maxContainer};
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @media only screen and (max-width: ${screen.break}) {
    padding: 0 32px;
  }
  @media only screen and (max-width: ${screen.smallBreak}) {
    padding: 0 24px;
  }
`
export const RowCus = styled(Row)`
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
`

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 540px;
  background-image: url(${Bg});
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: ${screen.break}) {
   height: 100%;
   padding-bottom: 10%;
  }
`

export const ImageLogo = styled.img`
  width: 100%;
  display: block;
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
  ${props => props.center && css`
    margin-left: 50%;
    transform: translateX(-50%);
  `}
  @media only screen and (max-width: ${screen.break}) {
    width: 40%;
  }
`

export const H2 = styled.h2`
  font-size: 40px;
  font-weight: 500 !important;
  margin: 0;
  letter-spacing: 1px;
  color: ${props => (props.color ? props.color : color.fontColor)};
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
  @media only screen and (max-width: ${screen.break}) {
    font-size: 6.82vw;
  }
`
export const H3 = styled.h3`
  font-size: 30px;
  color: ${color.fontColor};
  margin: 0;
`
export const H4 = styled.h4`
  font-size: 22px;
  color: ${color.fontColor};
  margin: 0;
  ${props => props.margin && css`
    margin: ${props.margin};
  `}
  @media only screen and (max-width: ${screen.break}) {
    font-size: 4.5vw;
  }
`

export const TitleWrapper = styled.div`
  margin: 88px 0 40px;
  ${H2} {
    font-family: 'helvetica' !important;
    font-weight: bold !important;
  }
  @media only screen and (max-width: ${screen.break}) {
    margin: 64px 0 40px;
    ${H2} {
      font-size: 9.5vw;
      line-height: 1.2;
    }
    ${H3} {
      font-size: 4.58vw;
    }
    ${ImageLogo} {
      display: inline-block;
      width: 20%;
      margin: 0 24px 32px 0;
    }
  }
`

export const CusLink = styled(Link)`
  text-decoration: underline;
  font-size: 22px;
  color: ${props => (props.color ? props.color : color.primaryColor)};
  @media only screen and (max-width: ${screen.break}) {
    font-size: 3.64vw;
  }
`

export default HeaderWrapper
