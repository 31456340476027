import React, { PureComponent } from 'react'
import { Col } from 'antd'
import MenuContainer from '../../components/menu'
import {
  MaxContainer, HeaderWrapper, H2, H3, H4,
  ImageLogo, TitleWrapper, CusLink, RowCus as Row,
} from './styled'
import Logo from '../../images/logo1.png'
import MolLogo from '../../images/mol_logo.png'
import IconSyndrome from '../../images/icon-syndrome.png'
import IconPose from '../../images/icon-pose.png'
import IconCalculate from '../../images/icon-calulate.png'
import { screen } from '../../constants/device-size'


const Title = () => (
  <TitleWrapper>
    <Row gutter={32} type='flex' justify='center'>
      <Col xs={24} md={4} lg={3}>
        <ImageLogo margin='0 0 24px' src={Logo} />
        <ImageLogo src={MolLogo} />
      </Col>
      <Col xs={24} md={16} lg={12}>
        <H2 margin='0 0 16px'>OFFICE COMPUTER WORK</H2>
        <H3>แอปพลิเคชั่นสำหรับผู้ที่ทำงานเกี่ยวข้องกับคอมพิวเตอร์ เพื่อที่จะได้รู้เท่าทันอาการ และวิธีรับมือกับอาการออฟฟิศซินโดรม</H3>
      </Col>
    </Row>
  </TitleWrapper>
)

const RowContent = ({
  detail, icon, flip, iconCenter, color,
}) => (
  <Row margin={iconCenter ? '80px 0' : '120px 0'} gutter={32} type='flex' justify='space-around' align='middle'>
    <Col xs={24} md={14} lg={12} order={flip ? 2 : 1}>
      <H2 color={color} margin='0 0 24px'>{detail.title}</H2>
      <H4 margin='0 0 16px'>{detail.description}</H4>
      <CusLink color={color} to={detail.link.url}>{detail.link.title}</CusLink>
    </Col>
    <Col xs={24} md={6} lg={4} order={flip ? 1 : 2}>
      <ImageLogo margin={iconCenter ? '0 0 40px' : '0'} center={iconCenter} src={icon} />
    </Col>
  </Row>
)

export default class Home extends PureComponent {
  constructor() {
    super()
    this.state = {
      currentMenu: 'mail',
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
    if (this.props.location.hash) { document.getElementById(this.props.location.hash.substring(1)).scrollIntoView() }
  }

  render() {
    const viewport = window.innerWidth
    return (
      <div>
        <HeaderWrapper>
          <MenuContainer transparentBg />
          <MaxContainer>
            <Title />
          </MaxContainer>
        </HeaderWrapper>
        <MaxContainer>
          <RowContent
            color='#FE8A71'
            flip={viewport <= screen.breakValue}
            iconCenter={viewport <= screen.breakValue}
            detail={{
              title: 'อาการออฟฟิศซินโดรม คืออะไร?',
              description: 'ออฟฟิศซินโดรม คือ กลุ่มอาการของปัญหาสุขภาพและโรคต่าง ๆ ที่มักจะพบในพนักงานสำนักงาน ซึ่งเกิดจากการปฏิบัติงานกับเครื่องคอมพิวเตอร์ด้วยท่าทางหรือพฤติกรรมที่ไม่เหมาะสม',
              link: {
                url: '/what-is-office-syndrome',
                title: 'อ่านต่อ',
              },
            }}
            icon={IconSyndrome}
          />
          <RowContent
            color='#F6CD61'
            flip
            iconCenter={viewport <= screen.breakValue}
            detail={{
              title: 'คำนวนค่าปรับตั้งคอมพิวเตอร์',
              description: 'แอปพลิเคชั่นจะทำการคำนวณการปรับตั้งค่าตำแหน่งต่างๆของการวางคอมพิวเตอร์ให้กับผู้ใช้งานได้อย่างเหมาะสม และป้องกันอาการออฟฟิศซินโดรม',
              link: {
                url: '/calculator',
                title: 'เริ่มคำนวณ',
              },
            }}
            icon={IconCalculate}
          />
          <RowContent
            color='#C96F6F'
            flip={viewport <= screen.breakValue}
            iconCenter={viewport <= screen.breakValue}
            detail={{
              title: 'วิธีการรับมือกับออฟฟิศซินโดรม',
              description: 'Office Computer Work ขอแนะนำท่านั่งที่ปลอดภัย และป้องกันอาการออฟฟิศซินโดรมได้',
              link: {
                url: '/pose',
                title: 'อ่านต่อ',
              },
            }}
            icon={IconPose}
          />
        </MaxContainer>
      </div>
    )
  }
}
