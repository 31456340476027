import styled from 'styled-components'
import { Col } from 'antd'
import { Link } from 'react-router-dom'
import Img from '../../components/common/image'

export const Wrapper = styled.div`
  padding: 50px 0;
`

export const Image = styled(Img)`
  object-fit: contain;
  width: 100%;
  margin: ${props => props.margin};
`

export const Card = styled(Col)`
  // border-bottom: solid 6px ${props => (props.isActive ? '#2a7078' : 'rgba(31, 172, 189, 0.90)')};
  border-radius: 5px;
  background-image: 
  linear-gradient(
    ${props => (props.isActive
    ? 'rgba(255, 255, 255, 0.0)'
    : 'rgba(255, 255, 255, 0.4)')},
    ${props => (props.isActive
    ? 'rgba(31, 172, 189, 0.90) 100%'
    : 'rgba(175, 223, 220, 0.90) 100%')}
  ),
  url('${props => props.background}');
  background-size: cover;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 15px !important;
`

export const MobileCard = styled(Link)`
  // border-bottom: solid 6px ${props => (props.isActive ? '#2a7078' : 'rgba(31, 172, 189, 0.90)')};
  border-radius: 5px;
  background-image: 
  linear-gradient(
    ${props => (props.isActive
    ? 'rgba(255, 255, 255, 0.0)'
    : 'rgba(255, 255, 255, 0.4)')},
    ${props => (props.isActive
    ? 'rgba(31, 172, 189, 0.90) 100%'
    : 'rgba(175, 223, 220, 0.90) 50%')}
  ),
  url('${props => props.background}');
  background-size: cover;
  height: 150px;
  display: flex;
  align-items: flex-end;
  padding: 15px !important;
  width: 320px;
  margin: 0 10px;
`

export const MobileCardWrapper = styled.div`
  margin: 15px -20px 0 -20px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`
