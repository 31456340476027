import styled from 'styled-components'
import {
  Row,
} from 'antd'
import {
  H4,
} from '../../components/common/text'
import { screen } from '../../constants/device-size'


export const CategoryRow = styled(Row)`
  margin: 48px 0;
  @media only screen and (max-width: ${screen.break}) {
    margin: 0;
    > div {
      margin: 32px 0 0;
    }
  }
`
export const CardWrapper = styled.div`
  border: 1px solid red;
`

export const CardImage = styled.img`
  border-radius: 6px;
  width: 100%;
  display: inline-block;
`

export const CardTitle = styled(H4)`
  font-size: 4vw;
`

export const Wrapper = styled.div`
  min-height: calc(100vh - 282px);
`

export default CardWrapper
