import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Row as RowAntd,
  Col as ColAntd,
} from 'antd'
import Img from '../common/image'
import devicesize, { screen } from '../../constants/device-size'
import { color } from '../../style/_variable'

export const Layout = styled.div`
  margin: 53px auto;
  @media ${devicesize.laptop} {
    max-width: 1024px;
  }
`

export const Row = styled(RowAntd)`
  background-color: ${props => props.solid && color.primaryColor};
  position: relative;
  ${props => props.padding && css`
    padding: ${props.padding};
  `}
  ${props => props.menu && css`
    background-image: ${!props.transparentBg && 'linear-gradient(to right, rgba(176, 223, 220, 1), #A4D3E5)'};
    margin: 0 !important;
  `}
  @media only screen and (max-width: ${screen.break}) {
    padding: 24px 24px 16px
  }
`

export const CusLink = styled(Link)`
  cursor: pointer;
  color: #002347;
  font-weight: 500;
  &:hover {
    color: #2795C2;
    text-decoration: underline;
  }
`

export const SubRow = styled(RowAntd)`
`

export const Col = styled(ColAntd)`
  text-align: ${props => props.align || 'center'};
  position: relative;
`

export const Image = styled(Img)`
  height: 50px;
`


export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > ${CusLink} {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  ${props => props.column && css`
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    transition: all 0.3s ease;
    margin-left: -16px;
    > ${CusLink} {
      width: 105%;
      padding: 16px 0;
      border-bottom: 1px solid ${color.secondaryColor};
      &:last-child {
        border: none;
      }
      opacity: 0;
      z-index: -99;
    }
    ${props.active && css`
      padding: 16px 0;
      max-height: 100%;
      overflow: hidden;
      > ${CusLink} {
        opacity: 1;
        z-index: 0;
      }
    `}
  `}
`

export const HamburgerMenu = styled.i`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${color.offWhite};
  cursor: pointer;
`
