import React, { PureComponent } from 'react'
import { Row, Col } from 'antd'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import {
  Form, Main, Radio, Button, Sub, Last, InputNumber, DeviceWrapper, Icon, GroupIcon, Select,
  MaxContainer,
} from './styled'
import MenuContainer from '../../components/menu'
import {
  H3, P, H5, SmallText,
} from '../../components/common/text'
import { color } from '../../style/_variable'

const RadioGroup = Radio.Group
const { Option } = Select

export default class Calculator extends PureComponent {
  constructor() {
    super()
    this.state = {
      gender: 0,
      height: undefined,
      deviceType: 0,
      posture: 0,
      canAdjustMonitorHeight: 0,
      maxMonitorHeight: undefined,
      minMonitorHeight: undefined,
      actualMonitorHeight: undefined,
      canAdjustKeyboardHeight: 0,
      minKeyboardHeight: undefined,
      maxKeyboardHeight: undefined,
      actualKeyboardHeight: undefined,
      minChairHeight: undefined,
      maxChairHeight: undefined,
      NBC: 11,
      canAdjustTableHeight: 0,
      maximumTableHeight: undefined,
      minimumTableHeight: undefined,
      actualTableHeight: undefined,
    }
  }

  validateInput = () => {
    const {
      height, gender, posture, deviceType, canAdjustMonitorHeight, maxMonitorHeight, minMonitorHeight, actualMonitorHeight,
      canAdjustKeyboardHeight, minKeyboardHeight, maxKeyboardHeight, actualKeyboardHeight, minChairHeight, maxChairHeight, canAdjustTableHeight,
      maximumTableHeight, minimumTableHeight, actualTableHeight,
    } = this.state
    return (
      !_.isUndefined(height)
      && (deviceType ? (gender ? (height >= 145 && height <= 180) : (height >= 155 && height <= 190)) : height >= 100)
      && (!deviceType ? (canAdjustMonitorHeight ? !_.isUndefined(maxMonitorHeight) && !_.isUndefined(minMonitorHeight) : !_.isUndefined(actualMonitorHeight)) : true)
      && (!deviceType ? (canAdjustKeyboardHeight ? !_.isUndefined(maxKeyboardHeight) && !_.isUndefined(minKeyboardHeight) : !_.isUndefined(actualKeyboardHeight)) : true)
      && (!posture ? (!_.isUndefined(minChairHeight) && !_.isUndefined(maxChairHeight)) : true)
      && (deviceType ? (canAdjustTableHeight ? !_.isUndefined(maximumTableHeight) && !_.isUndefined(minimumTableHeight) : !_.isUndefined(actualTableHeight)) : true)
    )
  }

  render() {
    const { height, gender, deviceType } = this.state
    return (
      <div>
        <MenuContainer solid={true} />
        <MaxContainer>
        <Row gutter={32} justify='center' type='flex'>
        <Col xs={24}>
          <H3 margin='0 0 24px' bold color={color.fontColor}>การคำนวนค่าปรับตั้งสถานีงานคอมพิวเตอร์</H3>
          <P>การปรับตั้งสถานีงานคอมพิวเตอร์ให้เหมาะสมกับแต่ละบุคคล ช่วยให้ลดโรงออฟฟิศซินโดรมได้</P>
          <Form>
            <Main>
              <Col sm={24} lg={8}>
                <H5 bold>1. เลือกเพศ</H5>
              </Col>
              <Col sm={24} lg={{ offset: 5, span: 11 }}>
                <RadioGroup onChange={e => this.setState({ gender: e.target.value })} value={gender}>
                  <Radio value={0}>ชาย</Radio>
                  <Radio value={1}>หญิง</Radio>
                </RadioGroup>
              </Col>
            </Main>
            <Main>
              <Col sm={24} lg={8}>
                <H5 bold>2. กรอกส่วนสูง *</H5>
              </Col>
              <Col sm={24} lg={{ offset: 5, span: 11 }}>
                <InputNumber
                  // min={gender ? 145 : 155} max={gender ? 180 : 190}
                  // onChange={(e) => {
                  //   const decimal = e - Math.floor(e)
                  //   if (decimal > 0) {
                  //     if (decimal.toFixed(1) <= 0.4) {
                  //       this.setState({ height: Math.floor(e) })
                  //     } else {
                  //       this.setState({ height: Math.ceil(e) })
                  //     }
                  //   }
                  // }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 110 || e.keyCode === 190) e.preventDefault()
                    // else this.setState({ height: e })
                  }}
                  onChange={(e) => { this.setState({ height: e }) }}
                  value={height}
                  step={10}
                />
                <P display='inline-block'>เซนติเมตร</P>
                {
                  deviceType
                    ? gender && !_.isNull(height)
                      ? (height < 145 || height > 180)
                        && <SmallText color={color.error}>ความสูงต้องอยู่ในช่วง 145-180 ซม.</SmallText>
                      : (height < 155 || height > 190)
                        && <SmallText color={color.error}>ความสูงต้องอยู่ในช่วง 155-190 ซม.</SmallText>
                    : height < 100 && <SmallText color={color.error}>ความสูงขั้นต่ำ 100 ซม.</SmallText>
                }
              </Col>
            </Main>
            <Main gutter={10}>
              <Col xs={24} lg={8}>
                <H5 bold>3. ประเภทคอมพิวเตอร์</H5>
              </Col>
              <Col xs={12} lg={{ offset: 5, span: 5 }}>
                <DeviceType id={0} isSelected={this.state.deviceType === 0} onSelect={e => this.setState({ deviceType: e })}/>
              </Col>
              <Col xs={12} lg={{ span: 5 }}>
                <DeviceType id={1} isSelected={this.state.deviceType === 1} onSelect={e => this.setState({ deviceType: e })}/>
              </Col>
            </Main>
            <Main>
              <Col sm={24} lg={8}>
                <H5 bold>4. ข้อมูลคอมพิวเตอร์{ this.state.deviceType ? 'พกพา' : 'ตั้งโต๊ะ'}</H5>
              </Col>
            </Main>
            <Main>
              <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                <H5 bold>4.1. ลักษณะการปฏิบัติงาน</H5>
              </Col>
              <Col xs={{ span: 24 }} lg={{ offset: 5, span: 11 }}>
                <RadioGroup onChange={e => this.setState({ posture: e.target.value })} value={this.state.posture}>
                  <Radio value={0}>นั่งปฏิบัติงาน</Radio>
                  <Radio value={1} disabled={!!this.state.deviceType}>ยืนปฏิบัติงาน</Radio>
                </RadioGroup>
              </Col>
            </Main>
            {
              this.state.deviceType
                ? <div>
                    <Sub>
                      <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                        <H5 bold>4.2. ขนาดจอภาพ</H5>
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ offset: 5, span: 11 }}>
                        <Select defaultValue={11} onChange={e => this.setState({ NBC: e })}>
                          <Option value={11}>11</Option>
                          <Option value={12}>12</Option>
                          <Option value={13}>13</Option>
                          <Option value={14}>14</Option>
                          <Option value={15}>15</Option>
                        </Select>
                        <H5>นิ้ว</H5>
                      </Col>
                    </Sub>
                    <Sub>
                      <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                        <H5 bold>4.3. ระดับความสูงโต๊ะ</H5>
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ offset: 5, span: 11 }}>
                        <RadioGroup onChange={e => this.setState({ canAdjustTableHeight: e.target.value })} value={this.state.canAdjustTableHeight}>
                          <Radio value={0}>ปรับไม่ได้</Radio>
                          <Radio value={1}>ปรับได้</Radio>
                        </RadioGroup>
                      </Col>
                    </Sub>
                    {
                      this.state.canAdjustTableHeight
                        ? <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับต่ำสุดของระดับโต๊ะ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber
                                  min={0}
                                  onBlur={() => {
                                    if (this.state.minimumTableHeight > this.state.maximumTableHeight && !_.isNull(this.state.maximumTableHeight)) this.setState({ minimumTableHeight: this.state.maximumTableHeight })
                                  }}
                                  onChange={e => this.setState({ minimumTableHeight: e })}
                                  value={this.state.minimumTableHeight}
                                  step={10}
                                />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                            <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                              <P>ระดับสูงสุดของระดับโต๊ะ *</P>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                              <InputNumber
                                min={0}
                                onBlur={() => {
                                  if (this.state.maximumTableHeight < this.state.minimumTableHeight && !_.isNull(this.state.minimumTableHeight)) this.setState({ maximumTableHeight: this.state.minimumTableHeight })
                                }}
                                onChange={e => this.setState({ maximumTableHeight: e })}
                                value={this.state.maximumTableHeight}
                                step={10}
                              />
                              <H5>เซนติเมตร</H5>
                            </Col>
                          </Main>
                        : <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับของระดับโต๊ะ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber min={0} max={250} onChange={e => this.setState({ actualTableHeight: e })} value={this.state.actualTableHeight} step={10} />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                          </Main>
                    }
                    {
                      this.state.posture === 0 && [
                        <Sub key='chair'>
                          <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                            <H5 bold>4.4. ระดับเบาะนั่ง</H5>
                          </Col>
                        </Sub>,
                        <Last key='charMinHeight'>
                          <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับต่ำสุดของระดับเบาะนั่ง *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber
                                  min={0}
                                  onBlur={() => {
                                    if (this.state.minChairHeight > this.state.maxChairHeight && !_.isNull(this.state.maxChairHeight)) this.setState({ minChairHeight: this.state.maxChairHeight })
                                  }}
                                  onChange={e => this.setState({ minChairHeight: e })} value={this.state.minChairHeight} step={10}
                                />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                            <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                              <P>ระดับสูงสุดของระดับเบาะนั่ง *</P>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                              <InputNumber
                                min={0}
                                onBlur={() => {
                                  if (this.state.maxChairHeight < this.state.minChairHeight && !_.isNull(this.state.minChairHeight)) this.setState({ maxChairHeight: this.state.minChairHeight })
                                }}
                                onChange={e => this.setState({ maxChairHeight: e })} value={this.state.maxChairHeight} step={10} />
                              <H5>เซนติเมตร</H5>
                            </Col>
                          </Main>
                        </Last>,
                      ]
                    }
                  </div>
                : <div>
                    <Sub>
                      <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                        <H5 bold>4.2. ระดับจอภาพ</H5>
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ offset: 5, span: 11 }}>
                        <RadioGroup onChange={e => this.setState({ canAdjustMonitorHeight: e.target.value })} value={this.state.canAdjustMonitorHeight}>
                          <Radio value={0}>ปรับไม่ได้</Radio>
                          <Radio value={1}>ปรับได้</Radio>
                        </RadioGroup>
                      </Col>
                    </Sub>
                    {
                      this.state.canAdjustMonitorHeight
                        ? <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับต่ำสุดของระดับจอภาพ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber
                                  min={0}
                                  onBlur={() => {
                                    if (this.state.minMonitorHeight > this.state.maxMonitorHeight && !_.isNull(this.state.maxMonitorHeight)) this.setState({ minMonitorHeight: this.state.maxMonitorHeight })
                                  }}
                                  onChange={e => this.setState({ minMonitorHeight: e })}
                                  value={this.state.minMonitorHeight}
                                  step={10}
                                />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                            <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                              <P>ระดับสูงสุดของระดับจอภาพ *</P>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                              <InputNumber
                                min={0}
                                onBlur={() => {
                                  if (this.state.maxMonitorHeight < this.state.minMonitorHeight && !_.isNull(this.state.minMonitorHeight)) this.setState({ maxMonitorHeight: this.state.minMonitorHeight })
                                }}
                                onChange={e => this.setState({ maxMonitorHeight: e })}
                                value={this.state.maxMonitorHeight}
                                step={10}
                              />
                              <H5>เซนติเมตร</H5>
                            </Col>
                          </Main>
                        : <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับของระดับจอภาพ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber min={0} max={250} onChange={e => this.setState({ actualMonitorHeight: e })} value={this.state.actualMonitorHeight} step={10} />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                          </Main>
                    }
                    <Sub>
                      <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                        <H5 bold>4.3. ระดับแป้นพิมพ์</H5>
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ offset: 5, span: 11 }}>
                        <RadioGroup onChange={e => this.setState({ canAdjustKeyboardHeight: e.target.value })} value={this.state.canAdjustKeyboardHeight}>
                          <Radio value={0}>ปรับไม่ได้</Radio>
                          <Radio value={1}>ปรับได้</Radio>
                        </RadioGroup>
                      </Col>
                    </Sub>
                    {
                      this.state.canAdjustKeyboardHeight
                        ? <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับต่ำสุดของระดับแป้นพิมพ์ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber
                                  min={0}
                                  onBlur={() => {
                                    if (this.state.minKeyboardHeight > this.state.maxKeyboardHeight && !_.isNull(this.state.maxKeyboardHeight)) { this.setState({ minKeyboardHeight: this.state.maxKeyboardHeight }) }
                                  }}
                                  onChange={e => this.setState({ minKeyboardHeight: e })}
                                  value={this.state.minKeyboardHeight}
                                  step={10}
                                />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                            <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                              <P>ระดับสูงสุดของระดับแป้นพิมพ์ *</P>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                              <InputNumber
                                min={0}
                                onBlur={() => {
                                  if (this.state.maxKeyboardHeight < this.state.minKeyboardHeight && !_.isNull(this.state.minKeyboardHeight)) this.setState({ maxKeyboardHeight: this.state.minKeyboardHeight })
                                }}
                                onChange={e => this.setState({ maxKeyboardHeight: e })}
                                value={this.state.maxKeyboardHeight}
                                step={10}
                              />
                              <H5>เซนติเมตร</H5>
                            </Col>
                          </Main>
                        : <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับของระดับแป้นพิมพ์ *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber min={0} max={250} onChange={e => this.setState({ actualKeyboardHeight: e })} value={this.state.actualKeyboardHeight} step={10} />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                          </Main>
                    }
                    {
                      this.state.posture === 0 && [
                        <Sub key='chair'>
                          <Col xs={{ span: 24 }} lg={{ offset: 1, span: 7 }}>
                            <H5 bold>4.4. ระดับเบาะนั่ง</H5>
                          </Col>
                        </Sub>,
                        <Last key='charMinHeight'>
                          <Main>
                            <Sub>
                              <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                                <P>ระดับต่ำสุดของระดับเบาะนั่ง *</P>
                              </Col>
                              <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                                <InputNumber
                                  min={0}
                                  onBlur={() => {
                                    if (this.state.minChairHeight > this.state.maxChairHeight && !_.isNull(this.state.maxChairHeight)) this.setState({ minChairHeight: this.state.maxChairHeight })
                                  }}
                                  onChange={e => this.setState({ minChairHeight: e })} value={this.state.minChairHeight} step={10}
                                />
                                <H5>เซนติเมตร</H5>
                              </Col>
                            </Sub>
                            <Col xs={{ span: 24 }} lg={{ offset: 2, span: 8 }}>
                              <P>ระดับสูงสุดของระดับเบาะนั่ง *</P>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ offset: 3, span: 11 }}>
                              <InputNumber
                                min={0}
                                onBlur={() => {
                                  if (this.state.maxChairHeight < this.state.minChairHeight && !_.isNull(this.state.minChairHeight)) this.setState({ maxChairHeight: this.state.minChairHeight })
                                }}
                                onChange={e => this.setState({ maxChairHeight: e })} value={this.state.maxChairHeight} step={10} />
                              <H5>เซนติเมตร</H5>
                            </Col>
                          </Main>
                        </Last>,
                      ]
                    }
                  </div>
            }
          </Form>
        </Col>
      </Row>
      <Row type='flex' justify='center' style={{ marginBottom: 48 }}>
      <Link
        to={{
          pathname: '/result',
          state: { ...this.state },
        }}
      >
        <Button
          type="primary"
          disabled={!this.validateInput()}
        >
          คำนวณผล
        </Button>
      </Link>
      </Row>
        </MaxContainer>
      </div>
    )
  }
}

export const CalculatorFreeze = () => (
  <div>
    <MenuContainer solid={true} />
      <MaxContainer>
        <Row gutter={32} justify='center' type='flex'>
          <Col xs={24}>
            <H3 margin='0 0 24px' bold color={color.fontColor}>การคำนวนค่าปรับตั้งสถานีงานคอมพิวเตอร์</H3>
            <P>การปรับตั้งสถานีงานคอมพิวเตอร์ให้เหมาะสมกับแต่ละบุคคล ช่วยให้ลดโรงออฟฟิศซินโดรมได้</P>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <P style={{ fontSize: 18 }} color='red'>ขอบคุณที่ให้ความสนใจ เรากำลังปรับปรุงสูตรคำนวณให้เหมาะสมกับคุณมากขึ้น ระบบจะเปิดให้บริการในเร็วๆนี้</P>
        </Row>
      </MaxContainer>
  </div>
)

const DeviceType = ({ id, isSelected, onSelect }) => (id ? (
  <DeviceWrapper onClick={() => onSelect(1)} isSelected={isSelected}>
    <Icon className="fas fa-laptop" isSelected={isSelected} />
    <P margin='16px 0 0' color={isSelected ? 'rgba(27, 171, 188, 0.9)' : 'rgba(32, 32, 32, 0.5)'}>คอมพิวเตอร์พกพา</P>
  </DeviceWrapper>
) : (
  <DeviceWrapper onClick={() => onSelect(0)} isSelected={isSelected}>
    <GroupIcon isSelected={isSelected}>
      <Icon className="fas fa-desktop" />
      <Icon className="fas fa-keyboard" />
      <span className='fas cpu' />
      <span className="fas mouse" />
    </GroupIcon>
    <P margin='16px 0 0' color={isSelected ? 'rgba(27, 171, 188, 0.9)' : 'rgba(32, 32, 32, 0.5)'}>คอมพิวเตอร์ตั้งโต๊ะ</P>
  </DeviceWrapper>
))
