import React from 'react'
import { Breadcrumb } from './styled'

export default ({ list }) => (
  <Breadcrumb>
    {
      list.map((v, i) => {
        if (v.link) {
          return <Breadcrumb.Item key={i}><a href={v.link}>{v.label}</a></Breadcrumb.Item>
        } return <Breadcrumb.Item key={i}>{v.label}</Breadcrumb.Item>
      })
    }
  </Breadcrumb>
)
