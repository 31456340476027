import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { color } from '../../../style/_variable'
import { screen } from '../../../constants/device-size'

export const H1 = styled.h1`
  margin: ${props => props.margin || 0} !important;
`

export const H2 = styled.h2`
  color: ${props => props.color};
  text-align: ${props => props.align};
  padding: ${props => props.padding};
  margin: ${props => props.margin || 0} !important;
  font-size: 40px;
  ${props => css`
    ${props.color && css`color: ${props.color};`}
    ${props.bold && css`font-weight: 500 !important;`}
  `}
`

export const H3 = styled.h3`
  margin: ${props => props.margin};
  font-size: 30px;
  ${props => css`
    ${props.color && css`color: ${props.color};`}
    ${props.bold && css`font-weight: 500 !important;`}
  `}
`

export const H4 = styled.h4`
  font-size: 22px;
  ${props => css`
    ${props.bold && css`font-weight: 400 !important;`}
    ${props.margin && css`margin: ${props.margin};`}
  `}
  color: ${props => props.color};
`

export const H5 = styled.span`
  font-size: 16px;
  ${props => css`
    ${props.bold && css`font-weight: 400 !important;`}
    ${props.margin && css`margin: ${props.margin};`}
  `}
  color: ${props => props.color};
  @media only screen and (max-width: ${screen.break}) {
    display: inline-block;
    margin-bottom: 16px;
  }
`

export const P = styled.p`
  margin-bottom: 0 !important;
  text-align: ${props => props.align};
  margin: ${props => props.margin};
  ${props => css`
    ${props.padding && css`padding: ${props.padding};`}
    ${props.bold && css`font-weight: 400 !important;`}
    ${props.display && css`display: ${props.display};`}
  `}
  color: ${props => props.color};
`

export const SmallText = styled.span`
  margin-bottom: 0 !important;
  font-size: 14px;
  display: block;
  text-align: ${props => props.align};
  margin: ${props => props.margin};
  ${props => css`
    ${props.padding && css`padding: ${props.padding};`}
    ${props.bold && css`font-weight: 400 !important;`}
  `}
  color: ${props => props.color};
`

export const CusLink = styled(Link)`
  text-decoration: underline;
  font-size: 16px;
  color: ${color.primaryColor};
  ${props => props.margin && css`
    margin: ${props.margin};
    display: inline-block;
  `}
`
