export const male11 = () => [
  {
    BH: 155,
    RSHST: 40,
    RTHST: 75,
    AB: 31,
    BS: 126,
    FX: 33,
  }, {
    BH: 156,
    RSHST: 40,
    RTHST: 76,
    AB: 31,
    BS: 126,
    FX: 34,
  }, {
    BH: 157,
    RSHST: 41,
    RTHST: 77,
    AB: 30,
    BS: 125,
    FX: 35,
  }, {
    BH: 158,
    RSHST: 41,
    RTHST: 78,
    AB: 30,
    BS: 125,
    FX: 35,
  }, {
    BH: 159,
    RSHST: 42,
    RTHST: 79,
    AB: 30,
    BS: 125,
    FX: 36,
  }, {
    BH: 160,
    RSHST: 42,
    RTHST: 80,
    AB: 30,
    BS: 125,
    FX: 36,
  }, {
    BH: 161,
    RSHST: 42,
    RTHST: 80,
    AB: 29,
    BS: 125,
    FX: 37,
  }, {
    BH: 162,
    RSHST: 43,
    RTHST: 81,
    AB: 29,
    BS: 125,
    FX: 37,
  }, {
    BH: 163,
    RSHST: 43,
    RTHST: 82,
    AB: 29,
    BS: 125,
    FX: 38,
  }, {
    BH: 164,
    RSHST: 44,
    RTHST: 83,
    AB: 28,
    BS: 125,
    FX: 39,
  }, {
    BH: 165,
    RSHST: 44,
    RTHST: 84,
    AB: 28,
    BS: 124,
    FX: 39,
  }, {
    BH: 166,
    RSHST: 44,
    RTHST: 85,
    AB: 28,
    BS: 124,
    FX: 40,
  }, {
    BH: 167,
    RSHST: 45,
    RTHST: 86,
    AB: 28,
    BS: 124,
    FX: 40,
  }, {
    BH: 168,
    RSHST: 45,
    RTHST: 87,
    AB: 27,
    BS: 124,
    FX: 41,
  }, {
    BH: 169,
    RSHST: 46,
    RTHST: 88,
    AB: 27,
    BS: 124,
    FX: 41,
  }, {
    BH: 170,
    RSHST: 46,
    RTHST: 88,
    AB: 27,
    BS: 124,
    FX: 42,
  }, {
    BH: 171,
    RSHST: 46,
    RTHST: 89,
    AB: 26,
    BS: 124,
    FX: 43,
  }, {
    BH: 172,
    RSHST: 47,
    RTHST: 90,
    AB: 27,
    BS: 124,
    FX: 43,
  }, {
    BH: 173,
    RSHST: 47,
    RTHST: 91,
    AB: 26,
    BS: 124,
    FX: 44,
  }, {
    BH: 174,
    RSHST: 48,
    RTHST: 92,
    AB: 26,
    BS: 123,
    FX: 43,
  }, {
    BH: 175,
    RSHST: 48,
    RTHST: 93,
    AB: 26,
    BS: 123,
    FX: 44,
  }, {
    BH: 176,
    RSHST: 48,
    RTHST: 94,
    AB: 26,
    BS: 123,
    FX: 44,
  }, {
    BH: 177,
    RSHST: 49,
    RTHST: 95,
    AB: 25,
    BS: 123,
    FX: 45,
  }, {
    BH: 178,
    RSHST: 49,
    RTHST: 95,
    AB: 25,
    BS: 123,
    FX: 46,
  }, {
    BH: 179,
    RSHST: 50,
    RTHST: 96,
    AB: 25,
    BS: 123,
    FX: 46,
  }, {
    BH: 180,
    RSHST: 50,
    RTHST: 97,
    AB: 25,
    BS: 123,
    FX: 46,
  }, {
    BH: 181,
    RSHST: 50,
    RTHST: 98,
    AB: 25,
    BS: 123,
    FX: 46,
  }, {
    BH: 182,
    RSHST: 51,
    RTHST: 99,
    AB: 25,
    BS: 123,
    FX: 46,
  }, {
    BH: 183,
    RSHST: 51,
    RTHST: 100,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 184,
    RSHST: 52,
    RTHST: 101,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 185,
    RSHST: 52,
    RTHST: 102,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 186,
    RSHST: 52,
    RTHST: 103,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 187,
    RSHST: 53,
    RTHST: 104,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 188,
    RSHST: 53,
    RTHST: 105,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 189,
    RSHST: 54,
    RTHST: 106,
    AB: 25,
    BS: 122,
    FX: 46,
  }, {
    BH: 190,
    RSHST: 54,
    RTHST: 107,
    AB: 25,
    BS: 122,
    FX: 46,
  },
]

export const male12 = () => [
  {
    BH: 155,
    RSHST: 40,
    RTHST: 72,
    AB: 26,
    BS: 123,
    FX: 32,
  }, {
    BH: 156,
    RSHST: 40,
    RTHST: 72,
    AB: 26,
    BS: 123,
    FX: 33,
  }, {
    BH: 157,
    RSHST: 41,
    RTHST: 73,
    AB: 26,
    BS: 123,
    FX: 33,
  }, {
    BH: 158,
    RSHST: 41,
    RTHST: 74,
    AB: 26,
    BS: 123,
    FX: 34,
  }, {
    BH: 159,
    RSHST: 42,
    RTHST: 75,
    AB: 26,
    BS: 123,
    FX: 34,
  }, {
    BH: 160,
    RSHST: 42,
    RTHST: 76,
    AB: 25,
    BS: 123,
    FX: 35,
  }, {
    BH: 161,
    RSHST: 42,
    RTHST: 77,
    AB: 25,
    BS: 122,
    FX: 35,
  }, {
    BH: 162,
    RSHST: 43,
    RTHST: 78,
    AB: 25,
    BS: 122,
    FX: 36,
  }, {
    BH: 163,
    RSHST: 43,
    RTHST: 79,
    AB: 25,
    BS: 122,
    FX: 36,
  }, {
    BH: 164,
    RSHST: 44,
    RTHST: 80,
    AB: 24,
    BS: 122,
    FX: 37,
  }, {
    BH: 165,
    RSHST: 44,
    RTHST: 80,
    AB: 24,
    BS: 122,
    FX: 38,
  }, {
    BH: 166,
    RSHST: 44,
    RTHST: 81,
    AB: 24,
    BS: 122,
    FX: 38,
  }, {
    BH: 167,
    RSHST: 45,
    RTHST: 82,
    AB: 23,
    BS: 122,
    FX: 39,
  }, {
    BH: 168,
    RSHST: 45,
    RTHST: 83,
    AB: 24,
    BS: 122,
    FX: 39,
  }, {
    BH: 169,
    RSHST: 46,
    RTHST: 84,
    AB: 23,
    BS: 122,
    FX: 40,
  }, {
    BH: 170,
    RSHST: 46,
    RTHST: 85,
    AB: 23,
    BS: 121,
    FX: 40,
  }, {
    BH: 171,
    RSHST: 46,
    RTHST: 86,
    AB: 23,
    BS: 121,
    FX: 41,
  }, {
    BH: 172,
    RSHST: 47,
    RTHST: 87,
    AB: 23,
    BS: 121,
    FX: 41,
  }, {
    BH: 173,
    RSHST: 47,
    RTHST: 88,
    AB: 22,
    BS: 121,
    FX: 42,
  }, {
    BH: 174,
    RSHST: 48,
    RTHST: 88,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 175,
    RSHST: 48,
    RTHST: 89,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 176,
    RSHST: 48,
    RTHST: 90,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 177,
    RSHST: 49,
    RTHST: 91,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 178,
    RSHST: 49,
    RTHST: 92,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 179,
    RSHST: 50,
    RTHST: 93,
    AB: 22,
    BS: 121,
    FX: 43,
  }, {
    BH: 180,
    RSHST: 50,
    RTHST: 94,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 181,
    RSHST: 50,
    RTHST: 95,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 182,
    RSHST: 51,
    RTHST: 96,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 183,
    RSHST: 51,
    RTHST: 97,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 184,
    RSHST: 52,
    RTHST: 98,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 185,
    RSHST: 52,
    RTHST: 99,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 186,
    RSHST: 52,
    RTHST: 100,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 187,
    RSHST: 53,
    RTHST: 101,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 188,
    RSHST: 53,
    RTHST: 102,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 189,
    RSHST: 54,
    RTHST: 103,
    AB: 23,
    BS: 120,
    FX: 43,
  }, {
    BH: 190,
    RSHST: 54,
    RTHST: 104,
    AB: 23,
    BS: 120,
    FX: 43,
  },
]

export const male13 = () => [
  {
    BH: 155,
    RSHST: 40,
    RTHST: 69,
    AB: 23,
    BS: 121,
    FX: 32,
  }, {
    BH: 156,
    RSHST: 40,
    RTHST: 70,
    AB: 23,
    BS: 121,
    FX: 32,
  }, {
    BH: 157,
    RSHST: 41,
    RTHST: 71,
    AB: 23,
    BS: 121,
    FX: 33,
  }, {
    BH: 158,
    RSHST: 41,
    RTHST: 72,
    AB: 23,
    BS: 121,
    FX: 33,
  }, {
    BH: 159,
    RSHST: 42,
    RTHST: 73,
    AB: 22,
    BS: 121,
    FX: 34,
  }, {
    BH: 160,
    RSHST: 42,
    RTHST: 74,
    AB: 22,
    BS: 121,
    FX: 35,
  }, {
    BH: 161,
    RSHST: 42,
    RTHST: 75,
    AB: 22,
    BS: 120,
    FX: 35,
  }, {
    BH: 162,
    RSHST: 43,
    RTHST: 76,
    AB: 22,
    BS: 120,
    FX: 36,
  }, {
    BH: 163,
    RSHST: 43,
    RTHST: 77,
    AB: 22,
    BS: 120,
    FX: 36,
  }, {
    BH: 164,
    RSHST: 44,
    RTHST: 77,
    AB: 21,
    BS: 120,
    FX: 37,
  }, {
    BH: 165,
    RSHST: 44,
    RTHST: 78,
    AB: 21,
    BS: 120,
    FX: 37,
  }, {
    BH: 166,
    RSHST: 44,
    RTHST: 79,
    AB: 21,
    BS: 120,
    FX: 38,
  }, {
    BH: 167,
    RSHST: 45,
    RTHST: 80,
    AB: 21,
    BS: 120,
    FX: 38,
  }, {
    BH: 168,
    RSHST: 45,
    RTHST: 81,
    AB: 21,
    BS: 120,
    FX: 39,
  }, {
    BH: 169,
    RSHST: 46,
    RTHST: 82,
    AB: 21,
    BS: 120,
    FX: 39,
  }, {
    BH: 170,
    RSHST: 46,
    RTHST: 83,
    AB: 20,
    BS: 120,
    FX: 40,
  }, {
    BH: 171,
    RSHST: 46,
    RTHST: 83,
    AB: 20,
    BS: 120,
    FX: 41,
  }, {
    BH: 172,
    RSHST: 47,
    RTHST: 85,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 173,
    RSHST: 47,
    RTHST: 86,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 174,
    RSHST: 48,
    RTHST: 87,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 175,
    RSHST: 48,
    RTHST: 88,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 176,
    RSHST: 48,
    RTHST: 89,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 177,
    RSHST: 49,
    RTHST: 90,
    AB: 20,
    BS: 119,
    FX: 41,
  }, {
    BH: 178,
    RSHST: 49,
    RTHST: 90,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 179,
    RSHST: 50,
    RTHST: 91,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 180,
    RSHST: 50,
    RTHST: 92,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 181,
    RSHST: 50,
    RTHST: 93,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 182,
    RSHST: 51,
    RTHST: 94,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 183,
    RSHST: 51,
    RTHST: 95,
    AB: 21,
    BS: 119,
    FX: 41,
  }, {
    BH: 184,
    RSHST: 52,
    RTHST: 97,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 185,
    RSHST: 52,
    RTHST: 98,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 186,
    RSHST: 52,
    RTHST: 98,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 187,
    RSHST: 53,
    RTHST: 99,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 188,
    RSHST: 53,
    RTHST: 100,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 189,
    RSHST: 54,
    RTHST: 101,
    AB: 21,
    BS: 118,
    FX: 41,
  }, {
    BH: 190,
    RSHST: 54,
    RTHST: 102,
    AB: 21,
    BS: 118,
    FX: 41,
  },
]

export const male14 = () => [
  {
    BH: 155,
    RSHST: 40,
    RTHST: 68,
    AB: 21,
    BS: 120,
    FX: 33,
  }, {
    BH: 156,
    RSHST: 40,
    RTHST: 69,
    AB: 21,
    BS: 120,
    FX: 33,
  }, {
    BH: 157,
    RSHST: 41,
    RTHST: 70,
    AB: 21,
    BS: 120,
    FX: 34,
  }, {
    BH: 158,
    RSHST: 41,
    RTHST: 71,
    AB: 21,
    BS: 120,
    FX: 34,
  }, {
    BH: 159,
    RSHST: 42,
    RTHST: 72,
    AB: 20,
    BS: 119,
    FX: 35,
  }, {
    BH: 160,
    RSHST: 42,
    RTHST: 73,
    AB: 20,
    BS: 119,
    FX: 35,
  }, {
    BH: 161,
    RSHST: 42,
    RTHST: 74,
    AB: 20,
    BS: 119,
    FX: 36,
  }, {
    BH: 162,
    RSHST: 43,
    RTHST: 75,
    AB: 20,
    BS: 119,
    FX: 36,
  }, {
    BH: 163,
    RSHST: 43,
    RTHST: 76,
    AB: 20,
    BS: 119,
    FX: 37,
  }, {
    BH: 164,
    RSHST: 44,
    RTHST: 76,
    AB: 20,
    BS: 119,
    FX: 37,
  }, {
    BH: 165,
    RSHST: 44,
    RTHST: 77,
    AB: 19,
    BS: 119,
    FX: 38,
  }, {
    BH: 166,
    RSHST: 44,
    RTHST: 78,
    AB: 20,
    BS: 119,
    FX: 38,
  }, {
    BH: 167,
    RSHST: 45,
    RTHST: 79,
    AB: 19,
    BS: 119,
    FX: 39,
  }, {
    BH: 168,
    RSHST: 45,
    RTHST: 80,
    AB: 19,
    BS: 119,
    FX: 40,
  }, {
    BH: 169,
    RSHST: 46,
    RTHST: 81,
    AB: 19,
    BS: 119,
    FX: 40,
  }, {
    BH: 170,
    RSHST: 46,
    RTHST: 82,
    AB: 19,
    BS: 119,
    FX: 40,
  }, {
    BH: 171,
    RSHST: 46,
    RTHST: 83,
    AB: 19,
    BS: 118,
    FX: 39,
  }, {
    BH: 172,
    RSHST: 47,
    RTHST: 84,
    AB: 19,
    BS: 118,
    FX: 39,
  }, {
    BH: 173,
    RSHST: 47,
    RTHST: 85,
    AB: 19,
    BS: 118,
    FX: 39,
  }, {
    BH: 174,
    RSHST: 48,
    RTHST: 86,
    AB: 19,
    BS: 118,
    FX: 39,
  }, {
    BH: 175,
    RSHST: 48,
    RTHST: 87,
    AB: 19,
    BS: 118,
    FX: 39,
  }, {
    BH: 176,
    RSHST: 48,
    RTHST: 88,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 177,
    RSHST: 49,
    RTHST: 89,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 178,
    RSHST: 49,
    RTHST: 90,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 179,
    RSHST: 50,
    RTHST: 91,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 180,
    RSHST: 50,
    RTHST: 92,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 181,
    RSHST: 50,
    RTHST: 93,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 182,
    RSHST: 51,
    RTHST: 93,
    AB: 20,
    BS: 118,
    FX: 39,
  }, {
    BH: 183,
    RSHST: 51,
    RTHST: 95,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 184,
    RSHST: 52,
    RTHST: 96,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 185,
    RSHST: 52,
    RTHST: 97,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 186,
    RSHST: 52,
    RTHST: 98,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 187,
    RSHST: 53,
    RTHST: 99,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 188,
    RSHST: 53,
    RTHST: 100,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 189,
    RSHST: 54,
    RTHST: 101,
    AB: 20,
    BS: 117,
    FX: 39,
  }, {
    BH: 190,
    RSHST: 54,
    RTHST: 102,
    AB: 20,
    BS: 117,
    FX: 39,
  },
]

export const male15 = () => [
  {
    BH: 155, RSHST: 40, RTHST: 65, AB: 16, BS: 117, FX: 32,
  },
  {
    BH: 156, RSHST: 40, RTHST: 66, AB: 17, BS: 117, FX: 32,
  },
  {
    BH: 157, RSHST: 41, RTHST: 67, AB: 16, BS: 117, FX: 33,
  },
  {
    BH: 158, RSHST: 41, RTHST: 68, AB: 16, BS: 117, FX: 33,
  },
  {
    BH: 159, RSHST: 42, RTHST: 69, AB: 16, BS: 116, FX: 34,
  },
  {
    BH: 160, RSHST: 42, RTHST: 70, AB: 16, BS: 116, FX: 34,
  },
  {
    BH: 161, RSHST: 42, RTHST: 71, AB: 16, BS: 116, FX: 35,
  },
  {
    BH: 162, RSHST: 43, RTHST: 72, AB: 15, BS: 116, FX: 36,
  },
  {
    BH: 163, RSHST: 43, RTHST: 73, AB: 15, BS: 116, FX: 36,
  },
  {
    BH: 164, RSHST: 44, RTHST: 74, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 165, RSHST: 44, RTHST: 75, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 166, RSHST: 44, RTHST: 76, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 167, RSHST: 45, RTHST: 76, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 168, RSHST: 45, RTHST: 77, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 169, RSHST: 46, RTHST: 78, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 170, RSHST: 46, RTHST: 79, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 171, RSHST: 46, RTHST: 80, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 172, RSHST: 47, RTHST: 81, AB: 16, BS: 116, FX: 36,
  },
  {
    BH: 173, RSHST: 47, RTHST: 83, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 174, RSHST: 48, RTHST: 84, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 175, RSHST: 48, RTHST: 85, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 176, RSHST: 48, RTHST: 85, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 177, RSHST: 49, RTHST: 86, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 178, RSHST: 49, RTHST: 87, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 179, RSHST: 50, RTHST: 88, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 180, RSHST: 50, RTHST: 89, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 181, RSHST: 50, RTHST: 90, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 182, RSHST: 51, RTHST: 91, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 183, RSHST: 51, RTHST: 92, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 184, RSHST: 52, RTHST: 93, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 185, RSHST: 52, RTHST: 94, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 186, RSHST: 52, RTHST: 95, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 187, RSHST: 53, RTHST: 96, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 188, RSHST: 53, RTHST: 97, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 189, RSHST: 54, RTHST: 98, AB: 17, BS: 115, FX: 36,
  },
  {
    BH: 190, RSHST: 54, RTHST: 99, AB: 17, BS: 114, FX: 36,
  },
]

export const female11 = () => [
  {
    BH: 145, RSHST: 35, RTHST: 68, AB: 25, BS: 123, FX: 32,
  },
  {
    BH: 146, RSHST: 35, RTHST: 69, AB: 25, BS: 123, FX: 32,
  },
  {
    BH: 147, RSHST: 36, RTHST: 70, AB: 26, BS: 123, FX: 32,
  },
  {
    BH: 148, RSHST: 36, RTHST: 70, AB: 26, BS: 123, FX: 33,
  },
  {
    BH: 149, RSHST: 37, RTHST: 71, AB: 26, BS: 123, FX: 33,
  },
  {
    BH: 150, RSHST: 37, RTHST: 72, AB: 27, BS: 123, FX: 33,
  },
  {
    BH: 151, RSHST: 37, RTHST: 73, AB: 26, BS: 124, FX: 34,
  },
  {
    BH: 152, RSHST: 38, RTHST: 74, AB: 27, BS: 124, FX: 34,
  },
  {
    BH: 153, RSHST: 38, RTHST: 75, AB: 27, BS: 124, FX: 34,
  },
  {
    BH: 154, RSHST: 39, RTHST: 75, AB: 27, BS: 124, FX: 35,
  },
  {
    BH: 155, RSHST: 39, RTHST: 76, AB: 27, BS: 124, FX: 35,
  },
  {
    BH: 156, RSHST: 39, RTHST: 77, AB: 27, BS: 124, FX: 36,
  },
  {
    BH: 157, RSHST: 40, RTHST: 78, AB: 28, BS: 124, FX: 36,
  },
  {
    BH: 158, RSHST: 40, RTHST: 79, AB: 28, BS: 124, FX: 36,
  },
  {
    BH: 159, RSHST: 41, RTHST: 80, AB: 28, BS: 125, FX: 37,
  },
  {
    BH: 160, RSHST: 41, RTHST: 81, AB: 28, BS: 125, FX: 37,
  },
  {
    BH: 161, RSHST: 42, RTHST: 82, AB: 29, BS: 125, FX: 37,
  },
  {
    BH: 162, RSHST: 42, RTHST: 82, AB: 29, BS: 125, FX: 38,
  },
  {
    BH: 163, RSHST: 42, RTHST: 83, AB: 29, BS: 125, FX: 38,
  },
  {
    BH: 164, RSHST: 43, RTHST: 84, AB: 29, BS: 125, FX: 38,
  },
  {
    BH: 165, RSHST: 44, RTHST: 86, AB: 30, BS: 125, FX: 39,
  },
  {
    BH: 166, RSHST: 44, RTHST: 87, AB: 30, BS: 125, FX: 39,
  },
  {
    BH: 167, RSHST: 44, RTHST: 88, AB: 30, BS: 126, FX: 39,
  },
  {
    BH: 168, RSHST: 44, RTHST: 88, AB: 30, BS: 126, FX: 39,
  },
  {
    BH: 169, RSHST: 45, RTHST: 88, AB: 30, BS: 126, FX: 40,
  },
  {
    BH: 170, RSHST: 45, RTHST: 89, AB: 30, BS: 126, FX: 40,
  },
  {
    BH: 171, RSHST: 46, RTHST: 90, AB: 31, BS: 126, FX: 40,
  },
  {
    BH: 172, RSHST: 46, RTHST: 91, AB: 31, BS: 126, FX: 41,
  },
  {
    BH: 173, RSHST: 46, RTHST: 92, AB: 31, BS: 126, FX: 41,
  },
  {
    BH: 174, RSHST: 47, RTHST: 93, AB: 31, BS: 126, FX: 41,
  },
  {
    BH: 175, RSHST: 47, RTHST: 94, AB: 31, BS: 126, FX: 42,
  },
  {
    BH: 176, RSHST: 48, RTHST: 95, AB: 32, BS: 126, FX: 42,
  },
  {
    BH: 177, RSHST: 48, RTHST: 96, AB: 32, BS: 126, FX: 42,
  },
  {
    BH: 178, RSHST: 49, RTHST: 96, AB: 32, BS: 126, FX: 43,
  },
  {
    BH: 179, RSHST: 49, RTHST: 97, AB: 32, BS: 127, FX: 43,
  },
  {
    BH: 180, RSHST: 49, RTHST: 98, AB: 32, BS: 127, FX: 43,
  },
]

export const female12 = () => [
  {
    BH: 145, RSHST: 35, RTHST: 65, AB: 20, BS: 120, FX: 30,
  },
  {
    BH: 146, RSHST: 35, RTHST: 65, AB: 21, BS: 120, FX: 30,
  },
  {
    BH: 147, RSHST: 36, RTHST: 66, AB: 21, BS: 120, FX: 31,
  },
  {
    BH: 148, RSHST: 36, RTHST: 67, AB: 21, BS: 120, FX: 31,
  },
  {
    BH: 149, RSHST: 37, RTHST: 68, AB: 22, BS: 120, FX: 31,
  },
  {
    BH: 150, RSHST: 37, RTHST: 69, AB: 22, BS: 121, FX: 32,
  },
  {
    BH: 151, RSHST: 37, RTHST: 70, AB: 22, BS: 121, FX: 32,
  },
  {
    BH: 152, RSHST: 38, RTHST: 71, AB: 23, BS: 121, FX: 32,
  },
  {
    BH: 153, RSHST: 38, RTHST: 71, AB: 22, BS: 121, FX: 33,
  },
  {
    BH: 154, RSHST: 39, RTHST: 72, AB: 23, BS: 121, FX: 33,
  },
  {
    BH: 155, RSHST: 39, RTHST: 73, AB: 23, BS: 121, FX: 34,
  },
  {
    BH: 156, RSHST: 39, RTHST: 74, AB: 23, BS: 122, FX: 34,
  },
  {
    BH: 157, RSHST: 40, RTHST: 75, AB: 24, BS: 122, FX: 34,
  },
  {
    BH: 158, RSHST: 40, RTHST: 75, AB: 23, BS: 122, FX: 35,
  },
  {
    BH: 159, RSHST: 41, RTHST: 76, AB: 24, BS: 122, FX: 35,
  },
  {
    BH: 160, RSHST: 41, RTHST: 77, AB: 24, BS: 122, FX: 35,
  },
  {
    BH: 161, RSHST: 42, RTHST: 78, AB: 24, BS: 122, FX: 36,
  },
  {
    BH: 162, RSHST: 42, RTHST: 79, AB: 25, BS: 122, FX: 36,
  },
  {
    BH: 163, RSHST: 42, RTHST: 80, AB: 25, BS: 122, FX: 36,
  },
  {
    BH: 164, RSHST: 43, RTHST: 80, AB: 25, BS: 123, FX: 37,
  },
  {
    BH: 165, RSHST: 43, RTHST: 81, AB: 26, BS: 123, FX: 37,
  },
  {
    BH: 166, RSHST: 44, RTHST: 82, AB: 26, BS: 123, FX: 37,
  },
  {
    BH: 167, RSHST: 44, RTHST: 83, AB: 25, BS: 123, FX: 38,
  },
  {
    BH: 168, RSHST: 44, RTHST: 84, AB: 26, BS: 123, FX: 38,
  },
  {
    BH: 169, RSHST: 45, RTHST: 85, AB: 26, BS: 123, FX: 38,
  },
  {
    BH: 170, RSHST: 45, RTHST: 86, AB: 26, BS: 123, FX: 39,
  },
  {
    BH: 171, RSHST: 46, RTHST: 87, AB: 26, BS: 123, FX: 39,
  },
  {
    BH: 172, RSHST: 46, RTHST: 87, AB: 27, BS: 124, FX: 39,
  },
  {
    BH: 173, RSHST: 46, RTHST: 88, AB: 27, BS: 124, FX: 40,
  },
  {
    BH: 174, RSHST: 47, RTHST: 89, AB: 27, BS: 124, FX: 40,
  },
  {
    BH: 175, RSHST: 47, RTHST: 90, AB: 27, BS: 124, FX: 40,
  },
  {
    BH: 176, RSHST: 48, RTHST: 91, AB: 27, BS: 124, FX: 41,
  },
  {
    BH: 177, RSHST: 48, RTHST: 92, AB: 28, BS: 124, FX: 41,
  },
  {
    BH: 178, RSHST: 49, RTHST: 93, AB: 28, BS: 124, FX: 41,
  },
  {
    BH: 179, RSHST: 49, RTHST: 94, AB: 28, BS: 124, FX: 42,
  },
  {
    BH: 180, RSHST: 49, RTHST: 94, AB: 28, BS: 124, FX: 43,
  },
]

export const female13 = () => [
  {
    BH: 145, RSHST: 35, RTHST: 63, AB: 17, BS: 118, FX: 29,
  },
  {
    BH: 146, RSHST: 35, RTHST: 63, AB: 17, BS: 118, FX: 30,
  },
  {
    BH: 147, RSHST: 36, RTHST: 64, AB: 18, BS: 118, FX: 30,
  },
  {
    BH: 148, RSHST: 36, RTHST: 65, AB: 18, BS: 118, FX: 30,
  },
  {
    BH: 149, RSHST: 37, RTHST: 66, AB: 18, BS: 118, FX: 31,
  },
  {
    BH: 150, RSHST: 37, RTHST: 67, AB: 19, BS: 119, FX: 31,
  },
  {
    BH: 151, RSHST: 37, RTHST: 67, AB: 19, BS: 119, FX: 32,
  },
  {
    BH: 152, RSHST: 38, RTHST: 68, AB: 19, BS: 119, FX: 32,
  },
  {
    BH: 153, RSHST: 38, RTHST: 69, AB: 20, BS: 119, FX: 32,
  },
  {
    BH: 154, RSHST: 39, RTHST: 70, AB: 19, BS: 119, FX: 33,
  },
  {
    BH: 155, RSHST: 39, RTHST: 71, AB: 20, BS: 119, FX: 33,
  },
  {
    BH: 156, RSHST: 39, RTHST: 72, AB: 20, BS: 120, FX: 33,
  },
  {
    BH: 157, RSHST: 40, RTHST: 72, AB: 20, BS: 120, FX: 34,
  },
  {
    BH: 158, RSHST: 40, RTHST: 73, AB: 21, BS: 120, FX: 34,
  },
  {
    BH: 159, RSHST: 41, RTHST: 74, AB: 21, BS: 120, FX: 35,
  },
  {
    BH: 160, RSHST: 41, RTHST: 75, AB: 21, BS: 120, FX: 35,
  },
  {
    BH: 161, RSHST: 42, RTHST: 76, AB: 21, BS: 120, FX: 35,
  },
  {
    BH: 162, RSHST: 42, RTHST: 77, AB: 21, BS: 120, FX: 36,
  },
  {
    BH: 163, RSHST: 42, RTHST: 78, AB: 22, BS: 121, FX: 36,
  },
  {
    BH: 164, RSHST: 43, RTHST: 78, AB: 22, BS: 121, FX: 36,
  },
  {
    BH: 165, RSHST: 43, RTHST: 79, AB: 22, BS: 121, FX: 37,
  },
  {
    BH: 166, RSHST: 44, RTHST: 80, AB: 22, BS: 121, FX: 37,
  },
  {
    BH: 167, RSHST: 44, RTHST: 81, AB: 23, BS: 121, FX: 37,
  },
  {
    BH: 168, RSHST: 44, RTHST: 82, AB: 23, BS: 121, FX: 38,
  },
  {
    BH: 169, RSHST: 45, RTHST: 83, AB: 23, BS: 121, FX: 38,
  },
  {
    BH: 170, RSHST: 45, RTHST: 84, AB: 24, BS: 122, FX: 38,
  },
  {
    BH: 171, RSHST: 46, RTHST: 84, AB: 23, BS: 122, FX: 39,
  },
  {
    BH: 172, RSHST: 46, RTHST: 85, AB: 24, BS: 122, FX: 39,
  },
  {
    BH: 173, RSHST: 46, RTHST: 86, AB: 24, BS: 122, FX: 40,
  },
  {
    BH: 174, RSHST: 47, RTHST: 87, AB: 24, BS: 122, FX: 40,
  },
  {
    BH: 175, RSHST: 47, RTHST: 88, AB: 24, BS: 122, FX: 41,
  },
  {
    BH: 176, RSHST: 48, RTHST: 89, AB: 24, BS: 122, FX: 41,
  },
  {
    BH: 177, RSHST: 48, RTHST: 90, AB: 25, BS: 122, FX: 41,
  },
  {
    BH: 178, RSHST: 49, RTHST: 91, AB: 25, BS: 122, FX: 41,
  },
  {
    BH: 179, RSHST: 49, RTHST: 91, AB: 25, BS: 123, FX: 42,
  },
  {
    BH: 180, RSHST: 49, RTHST: 92, AB: 26, BS: 123, FX: 42,
  },
]

export const female14 = () => [
  {
    BH: 145, RSHST: 35, RTHST: 62, AB: 15, BS: 116, FX: 29,
  },
  {
    BH: 146, RSHST: 35, RTHST: 63, AB: 16, BS: 116, FX: 29,
  },
  {
    BH: 147, RSHST: 36, RTHST: 63, AB: 16, BS: 117, FX: 31,
  },
  {
    BH: 148, RSHST: 36, RTHST: 64, AB: 16, BS: 117, FX: 31,
  },
  {
    BH: 149, RSHST: 37, RTHST: 65, AB: 16, BS: 117, FX: 31,
  },
  {
    BH: 150, RSHST: 37, RTHST: 66, AB: 17, BS: 117, FX: 31,
  },
  {
    BH: 151, RSHST: 37, RTHST: 67, AB: 17, BS: 117, FX: 31,
  },
  {
    BH: 152, RSHST: 38, RTHST: 67, AB: 17, BS: 118, FX: 33,
  },
  {
    BH: 153, RSHST: 38, RTHST: 68, AB: 17, BS: 118, FX: 33,
  },
  {
    BH: 154, RSHST: 39, RTHST: 69, AB: 18, BS: 118, FX: 33,
  },
  {
    BH: 155, RSHST: 39, RTHST: 70, AB: 18, BS: 118, FX: 34,
  },
  {
    BH: 156, RSHST: 39, RTHST: 71, AB: 18, BS: 118, FX: 34,
  },
  {
    BH: 157, RSHST: 40, RTHST: 72, AB: 18, BS: 118, FX: 35,
  },
  {
    BH: 158, RSHST: 40, RTHST: 72, AB: 19, BS: 119, FX: 35,
  },
  {
    BH: 159, RSHST: 41, RTHST: 73, AB: 19, BS: 119, FX: 35,
  },
  {
    BH: 160, RSHST: 41, RTHST: 74, AB: 19, BS: 119, FX: 36,
  },
  {
    BH: 161, RSHST: 42, RTHST: 75, AB: 19, BS: 119, FX: 36,
  },
  {
    BH: 162, RSHST: 42, RTHST: 76, AB: 20, BS: 119, FX: 36,
  },
  {
    BH: 163, RSHST: 42, RTHST: 77, AB: 20, BS: 119, FX: 37,
  },
  {
    BH: 164, RSHST: 43, RTHST: 77, AB: 20, BS: 120, FX: 37,
  },
  {
    BH: 165, RSHST: 43, RTHST: 78, AB: 21, BS: 120, FX: 37,
  },
  {
    BH: 166, RSHST: 44, RTHST: 79, AB: 21, BS: 120, FX: 38,
  },
  {
    BH: 167, RSHST: 44, RTHST: 80, AB: 21, BS: 120, FX: 38,
  },
  {
    BH: 168, RSHST: 44, RTHST: 81, AB: 21, BS: 120, FX: 39,
  },
  {
    BH: 169, RSHST: 45, RTHST: 82, AB: 21, BS: 120, FX: 39,
  },
  {
    BH: 170, RSHST: 45, RTHST: 83, AB: 21, BS: 120, FX: 40,
  },
  {
    BH: 171, RSHST: 46, RTHST: 83, AB: 22, BS: 121, FX: 40,
  },
  {
    BH: 172, RSHST: 46, RTHST: 84, AB: 22, BS: 121, FX: 40,
  },
  {
    BH: 173, RSHST: 46, RTHST: 85, AB: 22, BS: 121, FX: 40,
  },
  {
    BH: 174, RSHST: 47, RTHST: 86, AB: 23, BS: 121, FX: 40,
  },
  {
    BH: 175, RSHST: 47, RTHST: 87, AB: 23, BS: 121, FX: 40,
  },
  {
    BH: 176, RSHST: 48, RTHST: 88, AB: 23, BS: 121, FX: 40,
  },
  {
    BH: 177, RSHST: 48, RTHST: 89, AB: 24, BS: 121, FX: 40,
  },
  {
    BH: 178, RSHST: 49, RTHST: 90, AB: 24, BS: 121, FX: 40,
  },
  {
    BH: 179, RSHST: 49, RTHST: 90, AB: 25, BS: 122, FX: 40,
  },
  {
    BH: 180, RSHST: 49, RTHST: 91, AB: 25, BS: 122, FX: 40,
  },
]

export const female15 = () => [
  {
    BH: 145, RSHST: 35, RTHST: 59, AB: 11, BS: 113, FX: 28,
  },
  {
    BH: 146, RSHST: 35, RTHST: 60, AB: 11, BS: 113, FX: 29,
  },
  {
    BH: 147, RSHST: 36, RTHST: 61, AB: 11, BS: 113, FX: 29,
  },
  {
    BH: 148, RSHST: 36, RTHST: 62, AB: 11, BS: 113, FX: 30,
  },
  {
    BH: 149, RSHST: 37, RTHST: 62, AB: 12, BS: 114, FX: 30,
  },
  {
    BH: 150, RSHST: 37, RTHST: 63, AB: 12, BS: 114, FX: 30,
  },
  {
    BH: 151, RSHST: 37, RTHST: 64, AB: 12, BS: 114, FX: 31,
  },
  {
    BH: 152, RSHST: 38, RTHST: 65, AB: 13, BS: 114, FX: 31,
  },
  {
    BH: 153, RSHST: 38, RTHST: 65, AB: 13, BS: 115, FX: 32,
  },
  {
    BH: 154, RSHST: 39, RTHST: 66, AB: 13, BS: 115, FX: 32,
  },
  {
    BH: 155, RSHST: 39, RTHST: 67, AB: 14, BS: 115, FX: 32,
  },
  {
    BH: 156, RSHST: 39, RTHST: 68, AB: 14, BS: 115, FX: 33,
  },
  {
    BH: 157, RSHST: 40, RTHST: 69, AB: 14, BS: 115, FX: 33,
  },
  {
    BH: 158, RSHST: 40, RTHST: 69, AB: 14, BS: 116, FX: 34,
  },
  {
    BH: 159, RSHST: 41, RTHST: 70, AB: 15, BS: 116, FX: 34,
  },
  {
    BH: 160, RSHST: 41, RTHST: 71, AB: 15, BS: 116, FX: 34,
  },
  {
    BH: 161, RSHST: 42, RTHST: 72, AB: 15, BS: 116, FX: 35,
  },
  {
    BH: 162, RSHST: 42, RTHST: 73, AB: 15, BS: 116, FX: 36,
  },
  {
    BH: 163, RSHST: 42, RTHST: 74, AB: 15, BS: 116, FX: 36,
  },
  {
    BH: 164, RSHST: 43, RTHST: 74, AB: 16, BS: 117, FX: 36,
  },
  {
    BH: 165, RSHST: 43, RTHST: 75, AB: 16, BS: 117, FX: 36,
  },
  {
    BH: 166, RSHST: 44, RTHST: 76, AB: 17, BS: 117, FX: 36,
  },
  {
    BH: 167, RSHST: 44, RTHST: 77, AB: 17, BS: 117, FX: 36,
  },
  {
    BH: 168, RSHST: 44, RTHST: 78, AB: 18, BS: 117, FX: 36,
  },
  {
    BH: 169, RSHST: 45, RTHST: 79, AB: 18, BS: 117, FX: 36,
  },
  {
    BH: 170, RSHST: 45, RTHST: 80, AB: 18, BS: 118, FX: 36,
  },
  {
    BH: 171, RSHST: 46, RTHST: 81, AB: 19, BS: 118, FX: 36,
  },
  {
    BH: 172, RSHST: 46, RTHST: 82, AB: 19, BS: 118, FX: 36,
  },
  {
    BH: 173, RSHST: 46, RTHST: 83, AB: 20, BS: 118, FX: 36,
  },
  {
    BH: 174, RSHST: 47, RTHST: 84, AB: 20, BS: 118, FX: 36,
  },
  {
    BH: 175, RSHST: 47, RTHST: 84, AB: 20, BS: 118, FX: 36,
  },
  {
    BH: 176, RSHST: 48, RTHST: 85, AB: 21, BS: 118, FX: 36,
  },
  {
    BH: 177, RSHST: 48, RTHST: 86, AB: 21, BS: 119, FX: 37,
  },
  {
    BH: 178, RSHST: 49, RTHST: 87, AB: 21, BS: 119, FX: 37,
  },
  {
    BH: 179, RSHST: 49, RTHST: 88, AB: 22, BS: 119, FX: 37,
  },
  {
    BH: 180, RSHST: 49, RTHST: 89, AB: 22, BS: 119, FX: 37,
  },
]

export default (gender, NBC) => {
  switch (gender) {
    case 0:
      switch (NBC) {
        case 11: return male11()
        case 12: return male12()
        case 13: return male13()
        case 14: return male14()
        case 15: return male15()
        default: return male11()
      }
    case 1:
      switch (NBC) {
        case 11: return female11()
        case 12: return female12()
        case 13: return female13()
        case 14: return female14()
        case 15: return female15()
        default: return female11()
      }
    default: return male11()
  }
}
