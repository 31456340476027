import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import './index.css'
import 'antd/dist/antd.css'
import App from './App'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))
