import React from 'react'
import {
  Row, Col,
} from 'antd'
import {
  H3, P,
} from '../../components/common/text'
import Container from '../../components/container'
import { color } from '../../style/_variable'
import MolLogo from '../../images/mol_logo.png'
import {
  SupportWrapper, ImgLogo, Link, Wrapper,
} from './styled'

const About = () => (
  <Container>
    <Wrapper>
    <Row gutter={32} type='flex' justify='center'>
      <Col>
        <H3 margin='0 0 20px' bold color={color.fontColor}>เกี่ยวกับ ComWork</H3>
      </Col>
    </Row>
    <Row gutter={32}>
      <Col>
        <P margin='24px 0'>โมบายล์แอพพลิเคชั่น “ComWork” เป็นแอพพลิเคชั่นที่ให้ความรู้เกี่ยวกับอาการออฟฟิศซินโดรมซึ่งพนักงานสำนักงานที่ปฏิบัติกับเครื่องคอมพิวเตอร์อย่างต่อเนื่องกำลังมีปัญหานี้เป็นจำนวนมาก</P>
        <P margin='24px 0'>ComWork ให้ความรู้เกี่ยวกับเรื่องต่อไปนี้<br/>-  อาการออฟฟิศซินโดรมและความเชื่อมโยงกับปัญหาการยศาสตร์ในพนักงานสำนักงาน<br/>-  ท่านั่งปฏิบัติงานกับเครื่องคอมพิวเตอร์อย่างเหมาะสม<br/>-  ค่าปรับตั้งสถานีงานคอมพิวเตอร์ตามหลักการยศาสตร์สำหรับพนักงานแต่ละคน<br/>-  ท่าบริหารร่างกายสำหรับส่วนร่างกายที่ได้รับผลกระทบจากการปฏิบัติงานกับเครื่องคอมพิวเตอร์</P>
        <P margin='24px 0'>การจัดทำโมบายล์แอพพลิเคชั่น ComWork ได้รับความสนับสนุนทางด้านงบประมาณจากกองทุนเงินทดแทน สำนักงานประกันสังคม กระทรวงแรงงาน</P>
        <SupportWrapper>
          <P>สนับสนุนโดย สสปท.</P>
          <Link href='https://www.tosh.or.th/' target='_blank'>
            <ImgLogo src={MolLogo} />
          </Link>
        </SupportWrapper>
      </Col>
    </Row>
    </Wrapper>
  </Container>
)

export default About
