import styled, { css } from 'styled-components'
import { screen } from '../../constants/device-size'
import { color } from '../../style/_variable'

export const H4 = styled.h4`
  font-size: 22px;
  color: ${color.fontColor};
  margin: 0 0 16px;
  font-weight: 200 !important;
  ${props => css`
    ${props.bold && css`font-weight: 400 !important;`}
  `}
  @media only screen and (max-width: ${screen.break}) {
    font-size: 4.3vw;
  }
`

export const H3Cus = styled.h3`
  margin: ${props => props.margin};
  font-size: 30px;
  ${props => css`
    ${props.color && css`color: ${props.color};`}
    ${props.bold && css`font-weight: 500 !important;`}
  `}
  @media only screen and (max-width: ${screen.break}) {
    font-size: 5.8vw;
  }
`
