import React, { Component } from 'react'
import {
  Router, Route, Switch,
} from 'react-router-dom'
import Home from './pages/home'
import { CalculatorFreeze } from './pages/calculator' // TODO: ADD Calculator
import Footer from './components/footer'
import Content from './pages/content'
import About from './pages/about'
import Pose from './pages/pose'
import Result from './pages/result'
import OfficeSyndrome from './pages/office'
import WorkPosture from './pages/work-posture'
import history from './history'

history.listen(() => {
  window.scrollTo(0, 0)
})

class App extends Component {
  constructor() {
    super()
    this.state = {
      activeUser: 0,
    }
  }

  componentDidMount() {
    setInterval(() => {
      fetch('https://ga.officecomputerwork.com/') // eslint-disable-line
        .then(resp => resp.json())
        .then((resp) => {
          this.setState({
            activeUser: Number.parseInt(resp.totalVisitor, 10),
          })
        })
        .catch((error) => {
          console.log('error: ', error.message)
          this.setState({
            activeUser: 0,
          })
        })
    }, 5000)
  }

  render() {
    const { activeUser } = this.state
    return (
      <Router history={history}>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/calculator" component={CalculatorFreeze} />
            {/* <Route path="/calculator" component={Calculator} /> */}
            <Route path="/content/:id" component={Content} />
            <Route path="/about" component={About} />
            <Route path="/what-is-office-syndrome" component={OfficeSyndrome} />
            <Route path="/work-posture" component={WorkPosture} />
            <Route path="/pose" component={Pose} />
            <Route path="/result" component={Result} />
          </Switch>
          <Footer activeUser={activeUser} />
        </div>
      </Router>
    )
  }
}

export default App
