import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: calc(100vh - 234px);
`

export const Link = styled.a`
  /* margin: 0 0 0 24px; */
`

export const ImgLogo = styled.img`
  width: 100px;
`

export const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 0;
  flex-direction: column;
  justify-content: center;
`
