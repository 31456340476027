import React, { PureComponent } from 'react'
import {
  Col,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  Row, Image, CusLink,
  MenuWrapper, HamburgerMenu,
} from './styled'
import logo from '../../images/logo1.png'
import { screen } from '../../constants/device-size'

const menu = [
  // {
  //   label: 'บทความ',
  //   id: 'content',
  //   link: '/',
  // },
  {
    label: 'หน้าแรก',
    id: 'home',
    link: '/',
  }, {
    label: 'ออฟฟิศซินโดรมคืออะไร?',
    id: 'what-is-office-syndrome',
    link: '/what-is-office-syndrome',
  }, {
    label: 'ท่านั่งปฏิบัติงานกับคอมพิวเตอร์',
    id: 'pose',
    link: '/work-posture',
  }, {
    label: 'ท่าบริหาร',
    id: 'pose',
    link: '/pose',
  }, {
    label: 'คำนวณค่าปรับตั้งคอมพิวเตอร์',
    id: 'calculator',
    link: '/calculator',
  }, {
    label: 'เกี่ยวกับ Compwork',
    id: 'about',
    link: '/about',
  },
]


const MenuLink = props => (
  <MenuWrapper active={props.active} column={props.column}>
  {
    props.menu.map(v => (
      <CusLink
        key={v.id}
        to={{
          pathname: v.link,
          // hash: v.id || null,
          // state: v.id || null,
        }}
      >
        {v.label}
      </CusLink>
    ))
  }
  </MenuWrapper>
)

class MenuContainer extends PureComponent {
  constructor() {
    super()
    this.state = {
      activeMenu: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  toggleMenu = () => {
    this.setState({ activeMenu: !this.state.activeMenu })
  }

  render() {
    const viewport = window.innerWidth
    return (
      <Row menu gutter={32} type='flex' justify='space-between' padding='24px 48px 24px 64px' solid={this.props.solid} {...this.props}>
        <Link to='/'>
          <Image src={logo} />
        </Link>
        <Col md={20}>
          {
            viewport >= screen.breakValue
              ? <MenuLink menu={menu} />
              : <HamburgerMenu onClick={() => this.toggleMenu()} className="icon-menu" />
          }
        </Col>
        {
          viewport <= screen.breakValue
          && <Col xs={24}>
            <MenuLink active={this.state.activeMenu} column menu={menu} />
          </Col>
        }
      </Row>
    )
  }
}

export default MenuContainer

// export default () => (
//   <Row justify='space-between' type='flex' align='middle'>
//     <Image src={logo} />
//     <Col span={12}>
//       <SubRow justify='end' type='flex' align='middle'>
//         {
//           menu.map((v, i) => (
//             <Col key={i} md={8} lg={8} xl={8} >
//               <CusLink
//                 to={{
//                   pathname: v.link,
//                   hash: v.id || null,
//                   // state: v.id || null,
//                 }}
//               >
//                 {v.label}
//               </CusLink>
//             </Col>
//           ))
//         }
//       </SubRow>
//     </Col>
//   </Row>
// )
