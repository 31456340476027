import React, { PureComponent } from 'react'
import {
  Row, Col,
} from 'antd'
import {
  H3, P, H4, CusLink,
} from '../../components/common/text'
import Container from '../../components/container'
import {
  CategoryRow, CardImage, CardTitle, Wrapper,
} from './styled'
import { color } from '../../style/_variable'
import { screen } from '../../constants/device-size'
import PoseHand from '../../images/pose-hand.jpg'
import PoseBack from '../../images/pose-back.jpg'
import PoseFoot from '../../images/pose-foot.jpg'
import PoseHead from '../../images/pose-head.jpg'

class CategoryCard extends PureComponent {
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const { detail } = this.props
    const viewport = window.innerWidth
    return (
      <Row gutter={32} type='flex'>
        <Col xs={10} md={11}>
          <CardImage src={detail.image} />
        </Col>
        <Col xs={14} md={10}>
          {
            viewport <= screen.breakValue
              ? <CardTitle bold={viewport <= screen.smallBreakValue} margin='0 0 8px -16px'>{detail.name}</CardTitle>
              : <H4 bold margin='0 0 8px' color={color.fontColor}>{detail.name}</H4>
          }
          {
            viewport >= screen.breakValue && <P margin='0 0 24px'>{`ประกอบไปด้วย ${detail.total} ท่า`}</P>
          }
          <CusLink margin={viewport <= screen.breakValue && '0 0 0 -16px'} to={detail.link}>ดูรายละเอียดเพิ่มเติม</CusLink>
        </Col>
      </Row>
    )
  }
}

const CategoryData = [
  {
    name: 'ท่าบริหารคอและไหล่',
    total: 5,
    link: '/content/1',
    image: PoseHead,
  }, {
    name: 'ท่าบริหารมือ',
    total: 1,
    link: '/content/6',
    image: PoseHand,
  }, {
    name: 'ท่าบริหารหลังส่วนล่าง',
    total: 3,
    link: '/content/7',
    image: PoseBack,
  }, {
    name: 'ท่าบริหารขา หัวเข่า และข้อเท้า',
    total: 1,
    link: '/content/10',
    image: PoseFoot,
  },
]

const Pose = () => (
  <Container>
    <Wrapper>
      <Row gutter={32} type='flex' justify='center'>
        <Col>
          <H3 margin='0 0 24px' bold color={color.fontColor}>ท่าบริหารร่างกายสำหรับผู้ปฏิบัติงานกับเครื่องคอมพิวเตอร์</H3>
          <P margin='0 0 8px'>
          ท่าบริหารร่างกายประกอบไปด้วย 5 ส่วนคือ คอและไหล่, ข้อมือ, หลังส่วนล่าง และ ขา หัวเข่า ข้อเท้า  หรือ อ่านบทความท่านั่งปฏิบัติงานกับเครื่องคอมพิวเตอร์ เพื่อทำความเข้าใจเกี่ยวกับท่านั่งให้มากขึ้น
          </P>
          <CusLink margin='0 0 40px' to='/work-posture'>อ่านบทความท่านั่งปฏิบัติงานกับเครื่องคอมพิวเตอร์</CusLink>
        </Col>
      </Row>
      <CategoryRow gutter={32} type='flex'>
        {
          CategoryData.map((v, i) => {
            if (i <= 1) {
              return (
                <Col key={i} md={12}>
                  <CategoryCard detail={v} />
                </Col>
              )
            }
            return null
          })
        }
      </CategoryRow>
      <CategoryRow gutter={32} type='flex'>
      {
        CategoryData.map((v, i) => {
          if (i >= 2) {
            return (
              <Col key={i} md={12}>
                <CategoryCard detail={v} />
              </Col>
            )
          }
          return null
        })
      }
      </CategoryRow>
    </Wrapper>
  </Container>
)

export default Pose
