import React from 'react'

const Img = props => (
  <img
    className={props.className}
    src={props.src}
    alt={props.alt}
    onClick={e => props.handleClick && props.handleClick(e)}
  />
)

export default Img

/*
Usage:
- Import this class into styled-component file (ex.import Imgage from 'components/common/img')
- Import styled component class into jsx file (ex.import { Img } from './styled-components')
- Import image file (ex.import Logo from 'images/logo.png')
- Now you can use like this <Img src={props.logo} alt='product-image' />
*/
