import React from 'react'
import MenuContainer from '../menu'
import {
  ContainerWrapper, MaxWidthContainer,
} from './styled'

const Container = props => (
  <ContainerWrapper>
    <MenuContainer />
    <MaxWidthContainer>
    { props.children }
    </MaxWidthContainer>
  </ContainerWrapper>
)

export default Container
